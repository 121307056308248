import React, { useState } from "react";

// 3rd party imports
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.min.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/libs/fontawesome-pro/scss/fontawesome.scss";
import "./assets/libs/fontawesome-pro/scss/regular.scss";
import "./assets/libs/fontawesome-pro/scss/solid.scss";
import "./assets/libs/fontawesome-pro/scss/light.scss";
import "./assets/libs/fontawesome-pro/scss/duotone.scss";

// Local imports
// import logo from "./logo.svg";
import "./assets/sass/App.scss";
import SkipLinks from "./components/SkipLinks/SkipLinks";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HelplineButton from "./components/HelplineButton/HelplineButton";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ScrollToTopBtn from "./components/ScrollToTopBtn/ScrollToTopBtn";
import SurveyModal from "./components/SurveyModal/SurveyModal";
import NotFound from "./containers/NotFound/NotFound";
import Home from "./containers/Home/Home";
import Menu from "./containers/Menu/Menu";
import About from "./containers/About/About";
import Resources from "./containers/Resources/Resources";
import Main1 from "./containers/Main/Main1/Main1";
import Main2 from "./containers/Main/Main2/Main2";
import Main3 from "./containers/Main/Main3/Main3";
import Main4 from "./containers/Main/Main4/Main4";
import LanguageBar from "./components/LanguageBar/LanguageBar";

// SPANISH COMOPONENTS
import SkipLinksES from "./components/SkipLinks/SkipLinksES";
import HeaderES from "./components/Header/HeaderES";
import FooterES from "./components/Footer/FooterES";
import HelplineButtonES from "./components/HelplineButton/HelplineButtonES";

import NotFoundES from "./containers/NotFound/NotFoundES";
import HomeES from "./containers/Home/HomeES";
import MenuES from "./containers/Menu/MenuES";
import AboutES from "./containers/About/AboutES";
import ResourcesES from "./containers/Resources/ResourcesES";
import Main1ES from "./containers/Main/Main1/Main1ES";
import Main2ES from "./containers/Main/Main2/Main2ES";
import Main3ES from "./containers/Main/Main3/Main3ES";
import Main4ES from "./containers/Main/Main4/Main4ES";

// Language context
export const LanguageContext = React.createContext(null);

function App() {
  const [language, setLanguage] = useState(false);
  return (
    <>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <Router>
          <ScrollToTop />
          {!language ? <SkipLinks /> : <SkipLinksES />}
          <ProgressBar />
          <LanguageBar />
          {!language ? <Header /> : <HeaderES />}
          <main id="main-content">
            <div className="pmw-wrapper">
              <SurveyModal />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/about" element={<About />} />
                <Route path="/making-connections/*" element={<Main1 />} />
                <Route path="/managing-feelings/*" element={<Main2 />} />
                <Route
                  path="/seeking-and-accepting-help/*"
                  element={<Main3 />}
                />
                <Route path="/understanding-milestones/*" element={<Main4 />} />
                <Route path="/resource-center" element={<Resources />} />

                <Route path="/es/" element={<HomeES />} />
                <Route path="/es/menu" element={<MenuES />} />
                <Route path="/es/about" element={<AboutES />} />
                <Route path="/es/making-connections/*" element={<Main1ES />} />
                <Route path="/es/managing-feelings/*" element={<Main2ES />} />
                <Route
                  path="/es/seeking-and-accepting-help/*"
                  element={<Main3ES />}
                />
                <Route
                  path="/es/understanding-milestones/*"
                  element={<Main4ES />}
                />
                <Route path="/es/resource-center" element={<ResourcesES />} />

                <Route path="*" element={<NotFound />} />
                <Route path="/es/*" element={<NotFoundES />} />
              </Routes>
            </div>
          </main>
          {!language ? <Footer /> : <FooterES />}
          {!language ? <HelplineButton /> : <HelplineButtonES />}
          <ScrollToTopBtn />
        </Router>
      </LanguageContext.Provider>
    </>
  );
}

export default App;
