import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article1.scss";

import mainImg from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article1/02_Healthy-Connections.jpg";
import sect2Img from "../../../../assets/img/article/article1/03_Connect-with-Local-Groups.jpg";
import sect3Img from "../../../../assets/img/article/article1/04_Model-Supportive-Relationships.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article1() {
  return (
    <div className="container-fluid pmw-article1-wrapper">
      <div className="row pmw-mt25 pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Making and Modeling Healthy Social Connections
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="adults and children sitting outside laughing and talking"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            Your child can grow up healthy and happy when you have sources of
            emotional support and practices of self-care. Getting the emotional
            support you need can make you a more resilient parent. Just like
            your child needs support, relying on people in your network for
            different kinds of support can be a valuable source of comfort and
            guidance for you. You might need a sympathetic listening ear, a
            helpful advising voice, or a comforting shoulder to lean on. While
            everyone has different needs, remember that you are not alone on
            this journey. Finding help and support is possible, and it doesn't
            need to take a lot of your time.
          </p>
          <hr />
          <p className="callout">
            Remember that when you take better care of yourself and make the
            time to get the support you need, your children will benefit, too.
          </p>
          <hr />
          <p className="pmw-mb25">
            Your job as a caregiver will feel a little easier when you reach out
            to your family, friends, and community for support. Remember that
            when you take better care of yourself and make the time to get the
            support you need, your children will benefit, too. Here are some
            ideas to help you do just that.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Prioritize and make time for healthy social connections with other
          parents and friends.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="group of families playing at a playground"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Creating close and supportive personal relationships helps with your
            physical and mental well-being, reduces stress, and increases your
            resilience.
          </p>
          <p>
            You don't need to have a lot of people in your life or make a lot of
            time to maintain your connections with others. There may only be a
            couple people in your circle &#8212; as long as they keep you
            positive and help you grow stronger, healthier, more aware, and more
            connected with your values. Here are some ways to help you get
            started:
          </p>
          <ul>
            <li>
              Create small pockets of time to join a virtual gathering with
              friends.
            </li>
            <li className="pmw-mb25">
              Call your neighbors to check-in with them. Host a neighborhood
              family event or offer help for a neighbor in need.
            </li>
          </ul>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Connect with local groups and organizations in your community.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="group of parent and child playing in a playroom"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Your family is probably already part of a few communities. That
            might be a neighborhood, a school community, or a religious or
            spiritual community. You can continue to build positive social
            connections and support by finding common ground with people in your
            shared communities.
          </p>
          <p>
            For instance, you might connect with other parent communities that
            get what it's like to be a caregiver. Check out community events for
            families, participate in a local PTA event, or visit your child's
            school resource fairs. Stay a little longer after your child's
            rehearsal or practice to connect with other parents.
          </p>
          <p>
            You might also join groups and community organizations that share
            your interests and values, or that host activities you'd enjoy doing
            with your child. This might be your local library, family resource
            center, or neighborhood association.
          </p>
          <p className="pmw-mb25">
            Connecting with a community may be easier than you think and is
            totally worth the effort.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Model what positive and supportive relationships look like.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="family huddled in the couch laughing"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Positive, supportive relationships help you be the best caretaker
            you can be. They lighten the load of your caretaking journey. And
            this also benefits your child in many ways.
          </p>
          <p>
            With a community of support for yourself, your child can spend time
            with other caring, trusted adults. This includes extended family
            members, friends, mentors, or other members of your family's
            community. And when you feel better cared for and supported, your
            child will learn how to make more positive and supportive
            connections with other people.
          </p>
          <p>
            Their support circles will be larger. They'll probably be involved
            with the positive activities that you're involved with and benefit
            from them. You'll be a happier, healthier caregiver for them, too.
          </p>
          <p className="pmw-mb25">
            Caretaking means that you show up every day to provide many kinds of
            support for your child. Like them, it's important for you to have
            positive, supportive connections with other people. Just like a
            plant needs the right amount of water, sunlight, and fertilizer to
            grow, you need the right balance of support and nurturing from your
            community to get stronger, too. Connecting with trusted family,
            friends, and communities of support can make all the difference to
            ensure you and your family can lead happier and healthier lives.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>View Other Articles</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mom and daughter meditating"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/managing-feelings/article/article2">
            <strong>Four Keys to Healthy Emotional Expression</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <img
              src={article3Img}
              alt="parent and supporter hugging each other"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <strong>Finding and Securing Help When You Need It</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="family out on the park playing with balls"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/understanding-milestones/article/article4">
            <strong>Understanding Your Child's Milestones</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
