import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Milestones.scss";

import fullList from "../../../../assets/img/milestones/View-Full-List_Spanish.png";
import year1 from "../../../../assets/img/milestones/1-Year_Spanish.png";
import year2 from "../../../../assets/img/milestones/2-Years_Spanish.png";
import year3 from "../../../../assets/img/milestones/3-Years_Spanish.png";
import year4 from "../../../../assets/img/milestones/4-Years_Spanish.png";
import year5 from "../../../../assets/img/milestones/5-Years_Spanish.png";
import month2 from "../../../../assets/img/milestones/2-Months_Spanish.png";
import month4 from "../../../../assets/img/milestones/4-Months_Spanish.png";
import month6 from "../../../../assets/img/milestones/6-Months_Spanish.png";
import month9 from "../../../../assets/img/milestones/9-Months_Spanish.png";
import month15 from "../../../../assets/img/milestones/15-Months_Spanish.png";
import month18 from "../../../../assets/img/milestones/18-Months_Spanish.png";
import month30 from "../../../../assets/img/milestones/30-Months_Spanish.png";

export default function Milestones() {
  return (
    <div className="container-fluid pmw-milestones-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Explorar los Hitos Del Desarrollo</h2>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            A medida que sus hijos crecen, es útil observar cómo juegan,
            aprenden, hablan, actúan y se mueven. Esto le ayudará a comprender
            qué hitos han alcanzado y a asegurarse de que están encaminados en
            comparación con otros niños de su edad. Esta infografía le será
            útil. También le recomendamos que mantenga al proveedor de atención
            médica de sus hijos informado sobre los hitos que vayan alcanzando.
          </p>
        </div>
      </div>
      <div className="row hop-wrapper hop-wrapper-spanish navy-bg">
        <div className="col sect-p50 pmw-limit-width">
          <div className="row justify-content-center hop-r1">
            <div className="hop-top-spanish text-center">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/index.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={fullList}
                  alt="Ver lista completa"
                  className="img-fluid hop-top-spanish-img"
                />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r2">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-2mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month2} alt="2 meses" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-4mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month4} alt="4 meses" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r3">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-6mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month6} alt="6 meses" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r4">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-9mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month9} alt="9 meses" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-1yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year1} alt="1 año" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r5">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-15mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month15} alt="15 meses" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r6">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-18mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month18} alt="18 meses" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-2yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year2} alt="2 años" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r7">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-30mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month30} alt="30 meses" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r8">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-3yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year3} alt="3 años" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-4yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year4} alt="4 años" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r9">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/Spanish/actearly/milestones/milestones-5yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year5} alt="5 años" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
