import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// SASS
import "./Infographic.scss";

import Part1ES from "./components/Part1/Part1ES";
import BuildContext from "./contexts/BuildContext/BuildContext";
import ShowUIHelpers from "./components/ShowUIHelpers/ShowUIHelpers";
import ResetButton from "./components/ResetButton/ResetButton";
import { setCssVariable } from "./utils/setCssVariable";

import homeImg from "../../../../assets/img/infographic/Making-Connections-Infographic_Home.png";

export default function Infographic() {
  const [buildEnv, setBuildEnv] = useState(null);

  // sets the build environment variable and loads the "scroll down" element
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setBuildEnv("development");
    } else {
      setBuildEnv("production");
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, []);

  // set window height upon load.  Update window height if the page is resized
  useEffect(() => {
    updateWindowHeight();
    window.addEventListener("resize", updateWindowHeight, false);
  }, []);

  // Sets the CSS variable var(--window-height) to the current window height
  const updateWindowHeight = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    let windowHeightTop;
    let windowHeightBottom;
    let roomImgWidth;
    // console.log(windowWidth);

    if (windowHeight < 600) {
      setCssVariable("--desc-title", `1rem`);
      setCssVariable("--desc-bullet", `0.7rem`);

      windowHeightTop = window.innerHeight * 0.6;
      windowHeightBottom = window.innerHeight * 0.4;
      roomImgWidth = windowHeightTop / 1.0779;
      if (roomImgWidth > windowWidth) {
        windowHeightTop = windowWidth * 1.0779;
        roomImgWidth = windowWidth;
        windowHeightBottom = windowHeight - windowHeightTop;
      }
    } else {
      setCssVariable("--desc-title", `1.2rem`);
      setCssVariable("--desc-bullet", `0.9rem`);

      windowHeightTop = window.innerHeight * 0.65;
      windowHeightBottom = window.innerHeight * 0.35;
      roomImgWidth = windowHeightTop / 1.0779;
      if (roomImgWidth > windowWidth) {
        windowHeightTop = windowWidth * 1.0779;
        roomImgWidth = windowWidth;
        windowHeightBottom = windowHeight - windowHeightTop;
      }
    }
    setCssVariable("--window-height", `${windowHeight}px`);
    setCssVariable("--window-width", `${windowWidth}px`);
    setCssVariable("--window-height-top", `${windowHeightTop}px`);
    setCssVariable("--window-height-bottom", `${windowHeightBottom}px`);
    setCssVariable("--room-img-width", `${roomImgWidth}px`);
  };

  return (
    <div className="pmw-infographic-wrapper">
      <div className="container-fluid skyblue-bg navy-bb">
        <div className="row pmw-pt25 pmw-limit-width">
          <div className="col-12 text-center">
            <h2 className="mb-0">Un Hogar para Las Conexiones Saludables</h2>
          </div>
        </div>
        <div className="row pmw-pt25 pmw-limit-width">
          <div className="col-12 text-center px-0">
            <p className="infographic-p1">
              Cree relaciones positivas y de apoyo en su hogar&nbsp;
              <br className="d-none d-md-block" />y en sus alrededores con
              acciones simples todos los días.
            </p>
          </div>
        </div>
        <div className="row pmw-pt25 pmw-limit-width">
          <div className="col-12 text-center">
            <img src={homeImg} alt="imagen de una casa" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid grass-green-bg">
        <div className="row pmw-pt25 pmw-limit-width">
          <div className="col-12 text-center">
            <p className="infographic-p2">
              Desplácese por toda la casa para encontrar consejos sobre cómo
              &nbsp;
              <br className="d-none d-sm-block" />
              hacer conexiones saludables en casa.
            </p>
          </div>
        </div>
        <div className="row pmw-pt25 pmw-limit-width pmw-pb25">
          <div className="col-12 text-center">
            <button
              onClick={() => {
                const anchor = document.querySelector("#infographic");
                anchor.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              className="pmw-scrolldown-btn"
            >
              <i className="fal fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>

      <div id="infographic">
        <BuildContext.Provider value={{ buildEnv }}>
          <Part1ES step={"step1"} />
          {/* {buildEnv === "development" && <ShowUIHelpers />}
          {buildEnv === "development" && <ResetButton />} */}
        </BuildContext.Provider>
      </div>
    </div>
  );
}
