import React from "react";
import { Route, Routes } from "react-router-dom";

// SASS
import "./Main1.scss";

import Landing1 from "./Landing1/Landing1ES";
import Article1 from "./Article1/Article1ES";
import Infographic from "./Infographic/InfographicES";
import NotFound from "../../NotFound/NotFound";

export default function Main1() {
  return (
    <Routes>
      <Route path="" element={<Landing1 />} />
      <Route path="/article/article1" element={<Article1 />} />
      <Route path="/infographic" element={<Infographic />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
