import React from "react";
import { Link } from "react-router-dom";
import PaginationCarousel2 from "../../components/PaginationCarousel2/PaginationCarousel2";

// SASS
import "./Home.scss";

import heroImg1 from "../../assets/img/main/Hero-Image_Horizontal.png";
import heroImg2 from "../../assets/img/main/Hero-Image.png";
import icon1 from "../../assets/img/icon/Making-Connections-Icon.png";
import icon2 from "../../assets/img/icon/Managing-Feelings-Icon.png";
import icon3 from "../../assets/img/icon/Seeking-Help-Icon.png";
import icon4 from "../../assets/img/icon/Understanding-Milestones-Icon.png";

export default function Home() {
  return (
    <div className="container-fluid pmw-home-wrapper">
      <div className="row pmw-home-r1 pmw-mb50">
        <div className="col px-0">
          <img
            src={heroImg1}
            alt="Diverse group of hands holding sign saying Resilient parents to lead to healthier children"
            className="img-fluid d-none d-sm-block"
          />
          <img
            src={heroImg2}
            alt="Diverse group of hands holding sign saying Resilient parents to lead to healthier children"
            className="img-fluid d-block d-sm-none"
          />
        </div>
      </div>
      <div className="row pmw-home-r2 pmw-mb25 pmw-limit-width">
        <div className="col-12">
          <h2 className="orange-btn-txt">Welcome to PEACH Parenting!</h2>
          <p>
            <strong className="dark-lime-txt">
              Promoting Education, Awareness, Connection, and Health
            </strong>
          </p>
          <p className="pmw-mb50">
            We're so glad you're here to learn with our&nbsp;
            <br className="d-none d-sm-block" />
            community of parents and caregivers.
          </p>

          <Link className="pmw-btn orange" to="/about">
            About
          </Link>
        </div>
      </div>
      <div className="pmw-home-r3 pmw-mt50 pmw-mb10 pmw-limit-width">
        <div className="row">
          <div className="col">
            <h2 className="pmw-mb25">Resilience Builders</h2>
            <p className="pmw-mb15 pmw-w80">
              Learn how to improve your skills to bounce back from stress
              &#8212; also known as resilience &#8212; with these four
              resilience builders.
            </p>
            <p className="pmw-mb25 pmw-w80 small-txt">
              <i>Click on a topic below to get started.</i>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 link-box-wrapper">
            <Link className="link-box light-green-bg" to="/making-connections">
              <img src={icon1} alt="Making Connections icon" className="icon" />
              <p className="mb-0">
                Making
                <br />
                Connections
              </p>
            </Link>
          </div>
          <div className="col-6 link-box-wrapper">
            <Link className="link-box light-purple-bg" to="/managing-feelings">
              <img src={icon2} alt="Managing Feelings icon" className="icon" />
              <p className="mb-0">
                Managing
                <br />
                Feelings
              </p>
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50">
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-teal-bg"
              to="/seeking-and-accepting-help"
            >
              <img
                src={icon3}
                alt="Seeking and Accepting Help icon"
                className="icon"
              />
              <p className="mb-0">
                Seeking and
                <br />
                Accepting Help
              </p>
            </Link>
          </div>
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-pink-bg"
              to="/understanding-milestones"
            >
              <img
                src={icon4}
                alt="Understanding Milestones icon"
                className="icon"
              />
              <p className="mb-0">
                Understanding
                <br />
                Milestones
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="pmw-home-r4 pmw-mb75">
        <div className="row pmw-limit-width">
          <div className="col-12">
            <h2 className="pmw-mb25">How to Use This Website</h2>
          </div>
        </div>
        <div className="row pmw-limit-width">
          <div className="col carousel-wrapper2">
            <PaginationCarousel2></PaginationCarousel2>
          </div>
        </div>
      </div>

      <div className="row pmw-home-r5 pmw-mb50 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Resources</h2>
          <p className="pmw-mb50 pmw-w80">
            Here, you'll find a comprehensive list of resources from all
            sections on this website, plus more to support you.
          </p>

          <Link className="pmw-btn orange" to="/resource-center">
            All Resources
          </Link>
        </div>
      </div>
    </div>
  );
}
