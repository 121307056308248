const animate = {
  addClassOnEnter: (element, className, direction) => {
    if (direction === "down") {
      element.classList.add(className);
    }
  },
  removeClassOnEnter: (element, className, direction) => {
    if (direction === "down") {
      element.classList.remove(className);
    }
  },
  addClassOnExit: (element, className, direction) => {
    if (direction === "up") {
      element.classList.add(className);
    }
  },
  removeClassOnExit: (element, className, direction) => {
    if (direction === "up") {
      element.classList.remove(className);
    }
  },
  addClassOnExitBottom: (element, className, direction) => {
    if (direction === "down") {
      element.classList.add(className);
    }
  },
  addClassOnEnterBottom: (element, className, direction) => {
    if (direction === "up") {
      element.classList.add(className);
    }
  },
  removeClassOnEnterBottom: (element, className, direction) => {
    if (direction === "up") {
      element.classList.remove(className);
    }
  },
  removeClassOnExitBottom: (element, className, direction) => {
    if (direction === "down") {
      element.classList.remove(className);
    }
  },
  fadeInOnEnter: (element, direction) => {
    if (direction === "down") {
      element.style.opacity = 1;
    }
  },
  fadeInOnExit: (element, direction) => {
    if (direction === "up") {
      element.style.opacity = 1;
    }
  },
  fadeOutOnExit: (element, direction) => {
    if (direction === "up") {
      element.style.opacity = 0;
    }
  },
  fadeOutOnEnter: (element, direction) => {
    if (direction === "down") {
      element.style.opacity = 0;
    }
  },
  fadeOnProgress: (element, progress) => {
    element.style.opacity = progress;
  },
  fadeOutOnProgress: (element, progress) => {
    element.style.opacity = 1 - progress;
  },
  changeBodyBgColorDown: (color, direction) => {
    if (direction === "down") {
      document.body.style.backgroundColor = color;
    }
  },
  changeBodyBgColorUp: (color, direction) => {
    if (direction === "up") {
      document.body.style.backgroundColor = color;
    }
  },
  changeTextColorOnEnter: (element, color, direction) => {
    if (direction === "down") {
      element.style.color = color;
    }
  },
  changeTextColorOnExit: (element, color, direction) => {
    if (direction === "up") {
      element.style.color = color;
    }
  },
};

export default animate;
