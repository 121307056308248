import React from "react";
import { Route, Routes } from "react-router-dom";

// SASS
import "./Main3.scss";

import Landing3 from "./Landing3/Landing3ES";
import Article3 from "./Article3/Article3ES";
import NotFound from "../../NotFound/NotFound";

export default function Main3() {
  return (
    <Routes>
      <Route path="" element={<Landing3 />} />
      <Route path="/article/article3" element={<Article3 />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
