import React from "react";

// SASS
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div className="container pmw-not-found-wrapper">
      <div className="row">
        <div className="col">
          <h2 className="page-not-found text-center fadeIn animation-duration-one-second">
            Página no encontrada
          </h2>
        </div>
      </div>
    </div>
  );
}
