import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import "./SurveyModal.scss";

import headerLogoWhite from "../../assets/img/logo/PEACH-Parenting-Logo_Primary_Small_White_Peach-Parenting-Logo_Horizontal_Small_White.png";

export default function SurveyModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleShow();
  //   }, 2000);
  // }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={headerLogoWhite}
              alt="Peach Parenting Logo"
              className="survey-modal-logo"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We would like your help to improve the PEACH parenting website.</p>
          <p>
            Please{" "}
            <a
              href="https://www.surveymonkey.com/r/F835RVN"
              target={"_blank"}
              rel="noreferrer"
            >
              click here
            </a>{" "}
            to complete a 5-minute survey to share your thoughts.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
