import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Footer.scss";

import footerLogo from "../../assets/img/logo/Peach-Parenting-Logo_Primary_Small_White.png";

export default function Footer() {
  return (
    <div className="container-fluid pmw-footer">
      <div className="row sect-p40">
        <div className="col-12">
          <h2>Estamos Aquí para Escuchar.</h2>
          <h2 className="pmw-mb15">Estamos Aquí para Ayudar.</h2>
          <p className="pmw-mb10">Hable con un Especialista de Find Help</p>

          <p className="mb-0">
            <a href="tel:18002445373" className="number1">
              1-800-CHILDREN
            </a>
          </p>
          <p className="pmw-mb5">
            <a href="tel:18002445373" className="number2">
              (1-800-244-5373)
            </a>
          </p>
          <p className="pmw-mb25">De lunes a Viernes, de 8am a 6pm.</p>

          <a href="https://findhelpga.org/" target={"_blank"} rel="noreferrer">
            <i className="fas fa-globe-americas"></i>&nbsp;&nbsp;findhelpga.org
          </a>
        </div>
        <div className="col-12 pmw-mt25">
          <Link to="/">
            <img
              src={footerLogo}
              alt="Peach Parenting Logo"
              className="pmw-footer-logo"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
