import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article2.scss";

import mainImg from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article2/02_Self-Care.jpg";
import sect2Img from "../../../../assets/img/article/article2/03_Healthy-Routines.jpg";
import sect3Img from "../../../../assets/img/article/article2/04_Active-Listening.jpg";
import sect4Img from "../../../../assets/img/article/article2/05_Creative-Activites.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article2() {
  return (
    <div className="container-fluid pmw-article2-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Four Keys to Healthy Emotional Expression
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="Mom and daughter meditating"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            Did you know that helping your child to express and regulate their
            emotions in a safe, caring environment is one of the best skills you
            can teach them? It's crucial for healthy growth, so they can create
            positive and rewarding relationships later on. With these skills,
            your child can manage stress better, solve more problems, and
            navigate challenges.
          </p>
          <p className="pmw-mb25">
            Here are some ideas to build on what you're already doing to help
            your child manage their feelings.
          </p>
        </div>
      </div>

      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Take care of yourself and prioritize your self-care.
        </div>
      </div>

      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="mom sitting on top of a yoga mat drinking healthy juice"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            To help your child with their emotions and relationships, it's
            important to take care of your own mental, social, and emotional
            needs. Like a battery that needs to recharge in order to work well,
            taking time to fully recharge helps you be the best version of
            yourself. When you make time to meet your own needs, you can show up
            as a better parent for your children and family. You can grow
            resilience and weather the challenges of life.
          </p>
          <hr />
          <p className="callout">
            When you make time to meet your own needs, you can show up as a
            better parent for your children and family.
          </p>
          <hr />
          <p>
            Even if you don't have a lot of time, you can prioritize your
            self-care with just a few minutes a day. Try to do one thing every
            day that you enjoy doing to prioritize your physical or mental
            health.
          </p>
          <ul>
            <li>
              To get started, try journaling, meditating, going on a walk,
              taking a hot bath, or talking to friends.
            </li>
            <li className="pmw-mb25">
              Take care of your physical health by eating right, sleeping
              enough, and exercising.
            </li>
          </ul>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Build healthy routines to create security and predictability.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="mother brushing daughters' teeth"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Having predictable routines supports your child's emotional health
            by creating safety, security, and support. They also teach children
            healthy habits, like brushing their teeth. And they're good for you,
            too &#8212; they help keep you organized when life gets busy.
            Routines make it easier for you to spend time with your child and
            strengthen your relationship.
          </p>
          <p className="pmw-mb25">
            Try making a daily routine that works for your schedule, so you can
            stick to it. Think about activities you enjoy doing together to get
            started. Create time to eat meals together or read a bedtime story
            at the same time every night. Make sure your routines are regular,
            planned, and predictable, but flexible in case you need to make
            changes to it.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Listen with care to and help your child express and name emotions.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="daughter in wheelchair and father nose kissing "
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Helping your child regularly talk about how they are feeling through
            their ups and downs helps them understand their emotions. When
            children know that you listen and accept their feelings, they can
            accept and understand them without fear. They can also develop
            self-compassion and empathy for others.
          </p>
          <p className="pmw-mb25">
            Listen carefully by asking your child questions about their
            emotions, and reflecting on what they say while making eye contact
            with them. This is how your child can feel seen, cared for, and
            supported in expressing their emotions. You can also model healthy
            emotions by letting your child see yours.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">4</div>
        <div className="col-10 col-sm-11 txt-box">
          Do creative activities together and celebrate emotional expression.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect4Img}
            alt="father and daughter writing and drawing together"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Creative activities let your child express a different side of
            themselves and learn more about themselves so they can understand
            their feelings. Observe what your child likes to do, so you can try
            these activities together. This might be cooking, painting and
            drawing, poetry, or dancing, or any other creative activity.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Creating shared moments for talking about and expressing emotions
            with your child takes regular practice &#8212; just like sports or
            school. Through small, everyday habits, you have the power to create
            a safe, emotionally supportive space for your child. You are giving
            them a toolkit to build resilience, navigate challenges
            successfully, and thrive at school, work, and life.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>View Other Articles</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adults and children sitting outside laughing and talking"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/making-connections/article/article1">
            <strong>Making and Modeling Healthy Social Connections</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <img
              src={article3Img}
              alt="parent and supporter hugging each other"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <strong>Finding and Securing Help When You Need It</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="family out on the park playing with balls"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/understanding-milestones/article/article4">
            <strong>Understanding Your Child's Milestones</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
