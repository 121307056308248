import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";

export const data = [
  {
    bg: "light-green-bg2",
    img_src: require("../../assets/img/icon/Making-Connections-Icon.png"),
    alt: "Ícono de hacer conexiones",
    title1: "Cómo Hacer",
    title2: "Conexiones",
    desc: "Aprenda a construir relaciones sólidas para hacer crecer su red de apoyo dentro de su comunidad.",
    btn: "green2",
    to: "/es/making-connections",
  },
  {
    bg: "light-purple-bg2",
    img_src: require("../../assets/img/icon/Managing-Feelings-Icon.png"),
    alt: "Ícono de manejo de sentimientos",
    title1: "Cómo Manejar",
    title2: "Los Sentimientos",
    desc: "Ayude a su hijo a aprender cómo comunicarse, comprender y regular sus emociones para que pueda navegar lo que se le presente.",
    btn: "purple",
    to: "/es/managing-feelings",
  },
  {
    bg: "light-teal-bg",
    img_src: require("../../assets/img/icon/Seeking-Help-Icon.png"),
    alt: "Ícono de búsqueda y aceptación de ayuda",
    title1: "Cómo Buscar y",
    title2: "Aceptar Ayuda",
    desc: "Identifique cómo las personas de su red pueden ayudarlo a usted y a su familia a obtener el apoyo que necesitan, cuando lo necesitan.",
    to: "/es/seeking-and-accepting-help",
    btn: "dark-teal",
  },
  {
    bg: "light-pink-bg2",
    img_src: require("../../assets/img/icon/Understanding-Milestones-Icon.png"),
    alt: "Ícono de comprensión de los hitos",
    title1: "Cómo Comprender",
    title2: "Los Hitos",
    desc: "Conozca los hitos para asegurarse de que el desarrollo saludable de su hijo esté encaminado y comprenda cómo crece y aprende.",
    to: "/es/understanding-milestones",
    btn: "pink",
  },
];

function Pagination(props: { centerSlideDataIndex: number }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      {data.map((_, index) => {
        const isCenterSlide = props.centerSlideDataIndex === index;
        return (
          <div
            style={{
              height: 11,
              width: 11,
              marginLeft: 8,
              marginRight: 8,
              marginBottom: 15,
              borderRadius: "100%",
              background: isCenterSlide ? "#0a2340" : "white",
              border: "1px solid #0a2340",
            }}
            key={index}
          />
        );
      })}
    </div>
  );
}

export default function PaginationCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  // ResponsiveContainer will make the carousel to have the width of its parent
  return (
    <div style={{ width: "100%", position: "relative", margin: "0 auto" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let slideLength = 700;
          if (parentWidth < 992) slideLength = 700;
          if (parentWidth < 768) slideLength = 550;
          if (parentWidth < 576) slideLength = 300;
          return (
            <StackedCarousel
              ref={carouselRef}
              data={data}
              carouselWidth={parentWidth}
              // slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              slideWidth={slideLength}
              // height={900}
              slideComponent={Card}
              currentVisibleSlide={3}
              maxVisibleSlide={3}
              useGrabCursor
              onActiveSlideChange={onCenterSlideDataIndexChange}
            />
          );
        }}
      />
      <>
        <button
          style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          className="d-none d-lg-block carousel-btn"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <i className="fas fa-chevron-left navy-txt"></i>
        </button>
        <button
          style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
          className="d-none d-lg-block carousel-btn"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <i className="fas fa-chevron-right navy-txt"></i>
        </button>
      </>
      <Pagination centerSlideDataIndex={centerSlideDataIndex} />
    </div>
  );
}

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below
export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { bg, img_src, alt, title1, title2, desc, to, btn } = data[dataIndex];

  return (
    <div
      style={{
        width: "100%",
        userSelect: "none",
      }}
      className="my-slide-component-about"
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          textAlign: "center",
          // objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        className={"d-flex " + bg}
      >
        <div className="sect-p25 slide-about">
          <img src={img_src} alt={alt} className="slider-img" />
          <div className="slider-content-about">
            <div className="title">
              <h2 className="pmw-mb25">
                {title1}
                <br />
                {title2}
              </h2>
            </div>
            <div className="desc">
              <p className="pmw-mb50 ">{desc}</p>
            </div>
          </div>
          <Link className={"pmw-btn " + btn} to={to}>
            Aprenda más
          </Link>
        </div>
      </div>
    </div>
  );
});
