import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";

// SASS
// import "./ArticlePreview.scss";

export const data = [
  {
    num: "1",
    min: "MINUTO",
    desc: "Si tiene un minuto, lea consejos rápidos y de alto nivel para el área temática que haya seleccionado.",
    bg: "light-orange-bg",
    border: "navy-border-bottom",
  },
  {
    num: "3",
    min: "MINUTOS",
    desc: "Si solo tiene tres minutos, lea consejos rápidos y un artículo sobre el tema seleccionado.",
    bg: "medium-orange-bg white-txt",
    border: "white-border-bottom",
  },
  {
    num: "5",
    min: "MINUTOS",
    desc: "Si tiene cinco minutos, lea los consejos rápidos, consulte el artículo y complete una actividad en la página del tema.",
    bg: "orange-btn-bg white-txt",
    border: "white-border-bottom",
  },
];

function Pagination(props: { centerSlideDataIndex: number }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      {data.map((_, index) => {
        const isCenterSlide = props.centerSlideDataIndex === index;
        return (
          <div
            style={{
              height: 11,
              width: 11,
              marginLeft: 8,
              marginRight: 8,
              marginBottom: 15,
              borderRadius: "100%",
              background: isCenterSlide ? "#d8822d" : "white",
              border: "1px solid #d8822d",
            }}
            key={index}
          />
        );
      })}
    </div>
  );
}

export default function PaginationCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  // ResponsiveContainer will make the carousel to have the width of its parent
  return (
    <div style={{ width: "100%", position: "relative", margin: "0 auto" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let slideLength = 700;
          if (parentWidth < 992) slideLength = 700;
          if (parentWidth < 768) slideLength = 500;
          if (parentWidth < 576) slideLength = 300;
          return (
            <StackedCarousel
              ref={carouselRef}
              data={data}
              carouselWidth={parentWidth}
              // slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              slideWidth={slideLength}
              // height={900}
              slideComponent={Card}
              currentVisibleSlide={3}
              maxVisibleSlide={3}
              useGrabCursor
              onActiveSlideChange={onCenterSlideDataIndexChange}
            />
          );
        }}
      />
      <>
        <button
          style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          className="d-none d-lg-block carousel-btn"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <i className="fas fa-chevron-left orange-btn-txt"></i>
        </button>
        <button
          style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
          className="d-none d-lg-block carousel-btn"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <i className="fas fa-chevron-right orange-btn-txt"></i>
        </button>
      </>
      <Pagination centerSlideDataIndex={centerSlideDataIndex} />
    </div>
  );
}

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below
export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { num, min, desc, bg, border } = data[dataIndex];

  return (
    <div
      style={{
        width: "100%",
        userSelect: "none",
      }}
      className="my-slide-component-home"
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          textAlign: "center",
          // objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        className={"d-flex " + bg}
      >
        <div className="slider-content-box">
          <div className={"slider-content-home " + border}>
            <p className="num">{num}</p>
            <p className="min">{min}</p>
          </div>
          <p className="slider-content-home">{desc}</p>
        </div>
      </div>
    </div>
  );
});
