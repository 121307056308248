import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing2.scss";

import MainHeader from "../../../../components/MainHeader/MainHeader";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreview";

import heroImg from "../../../../assets/img/main/Managing-Feelings.png";
import headingIcon from "../../../../assets/img/icon/Managing-Feelings-Icon.png";
import articleImg from "../../../../assets/img/article/article2/01_Main-Image.jpg";

export default function Landing2() {
  return (
    <>
      <MainHeader
        heading1={"Managing"}
        heading2={"Feelings"}
        icon={headingIcon}
        iconAlt={"Managing Feelings Icon"}
        heroImg={heroImg}
        heroImgAlt={"family of 3 meditating"}
        heroImgClass={"light-purple-bg"}
        tips={true}
        article={true}
        video={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Help your child learn how to communicate, understand, and manage
              their emotions by building healthy habits and routines, and making
              time to express feelings together. By doing this, you can enable
              them to be better friends, students, and family members.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-purple-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Quick Tips</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>
              Here are some tools you can use to help your child understand and
              talk about their feelings.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Take care of yourself.</strong> Even when it feels like
              you have more important things to do, whenever possible it's
              important to prioritize self-care so that you can show up fully
              for your child and for yourself.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Build healthy routines.</strong> Create purposeful daily
              routines for your child to help make them feel more confident,
              secure, and safe.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Practice active listening.</strong> You can help your
              child express and name emotions by listening and repeating back
              what they say. Reflect together on what they might be feeling and
              experiencing.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">4</div>
            <div className="col-11 txt-box">
              <strong>Create and reflect.</strong> Choose a creative activity to
              do with your child. Use this opportunity to express shared
              feelings and build a stronger relationship.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Article</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Four Keys to Healthy Emotional Expression"}
              img={articleImg}
              imgAlt={"Mom and daughter meditating"}
              desc={
                "Did you know that helping your child to express and regulate their emotions in a safe, caring environment is one of the best skills you can teach them? It's crucial for healthy growth, so they can create positive and rewarding relationships later on. With these skills, your child can manage..."
              }
              link={"/managing-feelings/article/article2"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="video">
          <div className="col-12">
            <h2 className="pmw-mb25">Video</h2>
            <p className="pmw-mb25">
              What does becoming aware of your feelings, naming them, and
              managing feelings look like?
            </p>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <iframe
              width="315"
              height="560"
              src="https://www.youtube.com/embed/j4_ACgBeqvc?si=o7b4IF9zU_Fk9c_z&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item purple">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button purple collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">Other Resources on Managing Feelings</p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content purple">
                      <p>
                        <a
                          href="https://cssp.org/wp-content/uploads/2018/08/HO-2.4-Social-Emotional-Skill-Building-worksheet.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Use these activities with your child to build
                          emotional skills&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.cdc.gov/parents/essentials/communication/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Learn how to build strong communication with your
                          child&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.mhanational.org/sites/default/files/BACK%20TO%20SCHOOL%202014%20-%20Healthy%20Mental%20and%20Emotional%20Development.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Know what to expect for your child's emotional
                          development&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
