import React, { useEffect, useRef } from "react";

// SASS
// import "./Part1.scss";

// COMPONENTS
import Step from "../Step/Step";

// HELPERS
import animate from "../../utils/animate";

// LIBRARIES
import scrollama from "scrollama";

// ASSETS

// import floor1Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Bedroom.png";
// import floor2Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Childs-Room.png";
// import floor3Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Kitchen.png";
// import floor4Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Living-Room.png";
// import floor5Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Outside.png";
// import floor6Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Sidewalk.png";

import floorImg from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Entire-Floor-Plan.png";

export default function Part1({ step }) {
  // SCROLLAMA INSTANCE
  const scrolly = scrollama();

  // ANIMATION STEP DATA
  const animationSequence = [
    { stepNumber: "0", height: null, description: "spacer" },
    {
      stepNumber: "1",
      height: null,
      description: "scroll to floor2 Img, fade out desc1, fade in desc2",
    },
    {
      stepNumber: "2",
      height: null,
      description: "scroll to floor3 Img, fade out desc2, fade in desc3",
    },
    {
      stepNumber: "3",
      height: null,
      description: "scroll to floor4 Img, fade out desc3, fade in desc4",
    },
    {
      stepNumber: "4",
      height: null,
      description: "scroll to floor5 Img, fade out desc4, fade in desc5",
    },
    {
      stepNumber: "5",
      height: null,
      description: "scroll to floor6 Img, fade out desc5, fade in desc6",
    },
    {
      stepNumber: "6",
      height: null,
      description: "spacer",
    },
  ];

  // REFS
  const floorEl = useRef(null);
  const floorDesc1El = useRef(null);
  const floorDesc2El = useRef(null);
  const floorDesc3El = useRef(null);
  const floorDesc4El = useRef(null);
  const floorDesc5El = useRef(null);
  const floorDesc6El = useRef(null);

  // RUN WHEN THE COMPONENT MOUNTS
  useEffect(() => {
    scrolly
      .setup({
        step: `.${step}`,
        debug: false,
        threshold: 1,
        progress: true,
      })
      .onStepEnter(({ element, direction }) => {
        const step = element.dataset.step;
        switch (step) {
          case "1": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc0",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc1", direction);
            animate.fadeOutOnEnter(floorDesc1El.current, direction);
            animate.fadeInOnEnter(floorDesc2El.current, direction);
            break;
          }
          case "2": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc1",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc2", direction);
            animate.fadeOutOnEnter(floorDesc2El.current, direction);
            animate.fadeInOnEnter(floorDesc3El.current, direction);
            break;
          }
          case "3": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc2",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc3", direction);
            animate.fadeOutOnEnter(floorDesc3El.current, direction);
            animate.fadeInOnEnter(floorDesc4El.current, direction);
            break;
          }
          case "4": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc3",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc4", direction);
            animate.fadeOutOnEnter(floorDesc4El.current, direction);
            animate.fadeInOnEnter(floorDesc5El.current, direction);
            break;
          }
          case "5": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc4",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc5", direction);
            animate.fadeOutOnEnter(floorDesc5El.current, direction);
            animate.fadeInOnEnter(floorDesc6El.current, direction);
            break;
          }
          default:
            break;
        }
      })
      .onStepProgress(({ element, progress }) => {
        const step = element.dataset.step;
        switch (step) {
          default:
            break;
        }
      })
      .onStepExit(({ element, direction }) => {
        const step = element.dataset.step;
        switch (step) {
          case "1": {
            animate.addClassOnExit(floorEl.current, "floor-loc0", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc1", direction);
            animate.fadeInOnExit(floorDesc1El.current, direction);
            animate.fadeOutOnExit(floorDesc2El.current, direction);
            break;
          }
          case "2": {
            animate.addClassOnExit(floorEl.current, "floor-loc1", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc2", direction);
            animate.fadeInOnExit(floorDesc2El.current, direction);
            animate.fadeOutOnExit(floorDesc3El.current, direction);
            break;
          }
          case "3": {
            animate.addClassOnExit(floorEl.current, "floor-loc2", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc3", direction);
            animate.fadeInOnExit(floorDesc3El.current, direction);
            animate.fadeOutOnExit(floorDesc4El.current, direction);
            break;
          }
          case "4": {
            animate.addClassOnExit(floorEl.current, "floor-loc3", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc4", direction);
            animate.fadeInOnExit(floorDesc4El.current, direction);
            animate.fadeOutOnExit(floorDesc5El.current, direction);
            break;
          }
          case "5": {
            animate.addClassOnExit(floorEl.current, "floor-loc4", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc5", direction);
            animate.fadeInOnExit(floorDesc5El.current, direction);
            animate.fadeOutOnExit(floorDesc6El.current, direction);
            break;
          }
          default:
            break;
        }
      });
  }, [scrolly, step]);

  // CUSTOM FUNCTIONS
  return (
    <>
      <section className="wrapper">
        <div className="sticky-container sticky-container__1">
          {/* STICKY */}
          <div className="part1">
            <div className="floor-container grass-green-bg">
              <div className="floor-loc0" ref={floorEl}>
                <div className="floor">
                  <img
                    src={floorImg}
                    alt="top view of bedroom, bathroom, child's room, kitchen, living room, front yard, side walk, and street in vertically"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="desc-container green-btn-bg">
              <div className="desc desc-1" ref={floorDesc1El}>
                <p className="desc-title">Bedroom and Bathroom</p>
                <ul className="desc-bullet">
                  <li>Take care of your personal hygiene.</li>
                  <li>Start a daily journal.</li>
                  <li>
                    Reflect on three things you're grateful for from the day.
                  </li>
                  <li>Get at least 7-9 hours of sleep nightly.</li>
                </ul>
              </div>
              <div className="desc desc-2" ref={floorDesc2El}>
                <p className="desc-title">Child's Room</p>
                <ul className="desc-bullet">
                  <li>
                    Create space for reading, drawing, playing, and meaningful
                    activities.
                  </li>
                  <li>Join the fun.</li>
                  <li>Talk with your child.</li>
                  <li>Listen with care.</li>
                  <li>Explore your feelings together.</li>
                </ul>
              </div>
              <div className="desc desc-3" ref={floorDesc3El}>
                <p className="desc-title">Kitchen</p>
                <ul className="desc-bullet">
                  <li>Prepare and eat nutritious meals.</li>
                  <li>
                    Create healthy routines and habits like cleaning up and
                    washing dishes.
                  </li>
                  <li>
                    Do more than eat at the table: do homework and play games
                    together!
                  </li>
                </ul>
              </div>
              <div className="desc desc-4" ref={floorDesc4El}>
                <p className="desc-title">Living Room</p>
                <ul className="desc-bullet">
                  <li>
                    Talk with your family about what's going well and ways to
                    support each other so everyone feels cared for.
                  </li>
                  <li>
                    Set up a weekly board game night to spend quality time
                    together.
                  </li>
                  <li>
                    Watch a favorite family-friendly TV show or movie. Ask what
                    your child liked about the story and why.
                  </li>
                </ul>
              </div>
              <div className="desc desc-5" ref={floorDesc5El}>
                <p className="desc-title">Outside</p>
                <ul className="desc-bullet">
                  <li>Head outside to spend time with family and friends.</li>
                  <li>
                    Enjoy activities together like gardening, picnicking, and
                    playing sports.
                  </li>
                  <li>
                    Share and explain everyday tasks like yard work and outdoor
                    projects.
                  </li>
                </ul>
              </div>
              <div className="desc desc-6" ref={floorDesc6El}>
                <p className="desc-title">Neighborhood Sidewalk</p>
                <ul className="desc-bullet">
                  <li>Check in with neighbors to see how they're doing.</li>
                  <li>
                    Lend a hand: help with yard work and offer tools for
                    borrowing.
                  </li>
                  <li>
                    Go for walks and take a bike ride with your child. Build
                    stronger connections with your community.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* STEPS */}
        {animationSequence.map(
          ({ stepNumber, height, description, offset }) => {
            return (
              <Step
                stepSection={step}
                stepNumber={stepNumber}
                stepDescription={description}
                height={height}
                key={stepNumber}
                offset={offset}
              />
            );
          }
        )}
      </section>
    </>
  );
}
