import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Menu.scss";

import headerIcon1 from "../../assets/img/icon/About-Icon.png";
import headerIcon2 from "../../assets/img/icon/Making-Connections-Icon.png";
import headerIcon3 from "../../assets/img/icon/Managing-Feelings-Icon.png";
import headerIcon4 from "../../assets/img/icon/Seeking-Help-Icon.png";
import headerIcon5 from "../../assets/img/icon/Understanding-Milestones-Icon.png";
import headerIcon6 from "../../assets/img/icon/Resources-Icon.png";

export default function MenuES() {
  return (
    <div className="container-fluid pmw-menu-wrapper">
      <div className="row">
        <div className="col-12">
          <Link to="/es/about" className="menu-item">
            <img src={headerIcon1} alt="Acerca del icono" />
            Acerca de la iniciativa
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right orange-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/es/making-connections" className="menu-item">
            <img src={headerIcon2} alt="Ícono de hacer conexiones" />
            Cómo hacer conexiones
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right green-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/es/managing-feelings" className="menu-item">
            <img src={headerIcon3} alt="Ícono de manejo de sentimientos" />
            Cómo manejar los sentimientos
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right purple-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/es/seeking-and-accepting-help" className="menu-item">
            <img
              src={headerIcon4}
              alt="Ícono de búsqueda y aceptación de ayuda"
            />
            Cómo buscar y aceptar ayuda
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right dark-teal-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/es/understanding-milestones" className="menu-item">
            <img src={headerIcon5} alt="Ícono de comprensión de los hitos" />
            Cómo comprender los hitos
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right pink-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/es/resource-center" className="menu-item">
            <img src={headerIcon6} alt="Recursons del icono" />
            Centro de Recursos
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right dark-teal2-txt"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
