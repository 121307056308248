import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./SkipLinks.scss";

export default function SkipLinks() {
  return (
    <nav className="pmw-skiplinks-wrapper" aria-label="Skip links">
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>
      <Link className="skip-link" to="/">
        Go to home
      </Link>
    </nav>
  );
}
