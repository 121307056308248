import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article3.scss";

import mainImg from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article3/02_Reflect.jpg";
import sect2Img from "../../../../assets/img/article/article3/03_Reach-Out.jpg";
import sect3Img from "../../../../assets/img/article/article3/04_Connect-with-Confidence.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article3() {
  return (
    <div className="container-fluid pmw-article3-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Cómo Encontrar y Asegurar Ayuda Cuando la Necesita
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          {/* <div
            className="hero-img"
            style={{ backgroundImage: `url(${mainImg})` }}
          >
            <span className="sr-only">Hero image</span>
          </div> */}
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="padre y partidario abrazándose unos a otros"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Ser padre implica cumplir muchas funciones que van más allá de
            cuidar a los hijos. Los padres planifican y organizan eventos y los
            hacen posibles. Preparan comidas con anticipación, organizan
            actividades creativas y arreglan rápido las cosas cuando se rompen.
            Están presentes cada día para sus hijos de muchas maneras
            diferentes. Estas son algunas formas en que puede pedir ayuda a sus
            amigos, a sus familiares y a la comunidad cuando la necesite, para
            que usted y sus hijos puedan desarrollar la resiliencia que
            necesitan para prosperar.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Reflexione y aclare cómo puede conectarse con el apoyo y los recursos
          que necesita.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="madre mirando por la ventana"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Cuando está dando lo mejor de sí para ser un buen padre, hay muchas
            razones por las que quizá no quiera admitir que necesita ayuda.
            Quizá piense que sería una carga para sus amigos y familiares si les
            pidiera ayuda, o que debería poder tener todo bajo control. Quizá
            sienta que ha fracasado, que los demás le juzgarán o que el resto de
            las responsabilidades son demasiado para usted. Recuerde que siempre
            habrá alguien para ayudar, y que hay muchos tipos diferentes de
            recursos y apoyo disponibles para usted. No tienen por qué ser caros
            o complejos o llevar mucho tiempo.
          </p>
          <hr />
          <p className="callout">
            Recuerde que siempre habrá alguien para ayudar, y que hay muchos
            tipos diferentes de recursos y apoyo disponibles para usted.
          </p>
          <hr />
          <p className="pmw-mb25">
            Intente escribir un diario para tener una mejor idea de sus
            pensamientos y sentimientos y para aclarar sus necesidades.
            Considere qué tipo de apoyo sería más útil específicamente para
            usted y quién podría dárselo. ¿Necesita consejos para la crianza de
            sus amigos y familiares, asesoramiento de un profesional de la salud
            mental o acceso a una niñera constante? Escríbalo todo.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Identifique los apoyos concretos y elabore un plan para ponerse en
          contacto con las personas adecuadas.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="padre sosteniendo a un niño frente a la computadora y madre jugando con un niño en el sofá"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Ya ha identificado sus necesidades y las personas de su comunidad
            que le ayudarán a satisfacerlas. El paso siguiente es pensar en
            tareas concretas y posibles para las personas con las que se pondrá
            en contacto. Esta parte es especialmente importante si las personas
            a las que pedirá ayuda no son familiares. Haga que sea más fácil
            para los demás brindarle ayuda.
          </p>
          <p>
            Por ejemplo, puede pedirle a un amigo que cuide a sus hijos una
            noche a la semana o establecer un cronograma para que sus amigos y
            familiares le traigan comidas. Quizá le resulte útil pedirle a su
            pareja ayuda con las tareas domésticas. O puede preguntarles a sus
            amigos y familiares sobre sus experiencias con los desafíos a los
            que se está enfrentando ahora. Programe una cita con un psicólogo o
            con un asesor de salud mental si considera que le resultaría útil.
          </p>
          <p className="pmw-mb25">
            Puede elegir a alguien a quien delegarle tareas, responsabilidades y
            comunicaciones. O puede configurar un calendario compartido en el
            que las personas puedan anotarse para cuidar a sus hijos o ayudar
            con las tareas de la casa.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Conéctese con confianza con la comunidad que haya elegido y con otros
          recursos que puedan ayudarle.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="grupo de padres e hijos hablando en una habitación"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Ya terminó el arduo trabajo de pensar y priorizar. Póngase en
            contacto con otra persona de su red sin miedo y confíe en que le
            dará una mano. Consulte la lista que haya armado para decidir a
            quién contactar primero y cuándo. Piense en cómo le resulta más
            cómodo comunicar sus necesidades — ¿por mensaje de texto, mediante
            una llamada telefónica o en una reunión en persona?
          </p>
          <p className="pmw-mb25">
            Es posible que sea necesario hacer un seguimiento de las personas
            con las que ya se haya puesto en contacto. Es posible que sienta
            vergüenza, pero la vida a veces se complica. No tenga miedo de
            insistir cuando alguien no haya cumplido con algo que prometió. Si
            es una persona con la que puede contar para darle apoyo, estará
            encantada de que se lo haya recordado.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Tener el valor de conectarse con su comunidad hará que sea más fácil
            centrarse en ser el increíble padre que quiere ser. Si sus hijos ven
            que usted sabe pedir ayuda, ellos también aprenderán a pedirla
            cuando la necesiten. También le permitirá a usted crear conexiones
            más profundas con su familia, sus amigos, sus conocidos y una
            comunidad más amplia. Al igual que una casa que necesita una base
            sólida para sostener a sus miembros, usted también necesita esa base
            sólida para apoyar a su familia. Estos sencillos consejos le
            ayudarán a hacer exactamente eso, para que pueda mejorar el
            bienestar de sus hijos, la calidad de su vida familiar y todo su
            potencial como padre.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>Ver otros artículos</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adultos y niños sentados afuera riendo y hablando"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/making-connections/article/article1">
            <strong>
              Cómo Establecer y Modelar Conexiones Sociales Saludables
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mamá e hija meditando"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/managing-feelings/article/article2">
            <strong>
              Cuatro Claves para Expresar las Emociones de Forma Saludable
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="familia en el parque jugando con pelotas"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <strong>Cómo Comprender los Hitos de sus Hijos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
