import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article3.scss";

import mainImg from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article3/02_Reflect.jpg";
import sect2Img from "../../../../assets/img/article/article3/03_Reach-Out.jpg";
import sect3Img from "../../../../assets/img/article/article3/04_Connect-with-Confidence.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article3() {
  return (
    <div className="container-fluid pmw-article3-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Finding and Securing Help When You Need It
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          {/* <div
            className="hero-img"
            style={{ backgroundImage: `url(${mainImg})` }}
          >
            <span className="sr-only">Hero image</span>
          </div> */}
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="parent and supporter hugging each other"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            When you're a parent, you have many roles that go beyond caretaking
            for your child. You're an event planner, organizer, and executor.
            You're a meal planner, a creative organizer, and a quick fixer when
            things break. You show up every day for your child in many different
            ways. Here are some ways to ask for help from friends, family, and
            community when you need it, so that you and your child can build the
            resilience you need to thrive.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Reflect and clarify how you can connect yourself with the support and
          resources you need.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="mother staring out of the window"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            When you're trying your best to be a good parent, there are many
            reasons why you may not want to admit you need help. You might think
            asking for help is a burden to friends and family, or that you're
            supposed to have it all together. You might experience feelings of
            failure, judgment, or overwhelm with other responsibilities.
            Remember you are not alone, and there are many different kinds of
            resources and support available to help you. And it doesn't have to
            be expensive, difficult, or time-consuming.
          </p>
          <hr />
          <p className="callout">
            Remember you are not alone, and there are many different kinds of
            resources and support available to help you.
          </p>
          <hr />
          <p className="pmw-mb25">
            Try journaling to get a better idea of your thoughts and feelings
            and to clarify your needs. Consider what kind of support would be
            most helpful specifically for you, and from whom. Is it parenting
            advice from friends and family, counseling from a mental health
            professional, or access to a consistent babysitter? Write it all
            down.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Identify concrete supports, and make a plan to reach out.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="father holding a child in front of the computer and mother playing with child on the sofa"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            So you've identified your needs and the people in your community
            that will help you get there. A next step is to think about concrete
            and doable tasks for people you will reach out to. This is
            especially important if they're not family members. Make it easy for
            others to lend a helping hand.
          </p>
          <p>
            For instance, you might ask a friend for child-care one night a week
            or set up a meal schedule for friends and family to bring you meals.
            It might be helpful to talk to your partner about help with house
            chores. Or, you can ask friends and family about their experiences
            with the challenges you're facing. Schedule an appointment with a
            psychologist or mental health counselor if that feels helpful for
            you.
          </p>
          <p className="pmw-mb25">
            You might choose someone to delegate tasks, responsibilities, and
            communication to. Or, you might set up a shared calendar where
            people can sign up to help with childcare or pitch in around the
            house.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Connect with confidence to your chosen community and other resources
          that can support you.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="group of parent and child talking in a room"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Now you've done the hard work of thinking and prioritizing. Trust
            yourself to reach out, and trust someone else in your network to
            lend a hand. Look at the list you made before to decide who to reach
            out to first and when. Think about how it's most comfortable for you
            to communicate your needs — is it a text message, a phone call, or
            an in-person meeting?
          </p>
          <p className="pmw-mb25">
            Following up with people you reached out to already might be
            necessary. You might feel shy about it, but life gets busy
            sometimes. Be persistent when someone needs to follow up on
            something they promised. If they're a trusted source of support,
            they'll be glad you did.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Having the courage to connect with your community will make it
            easier to focus on being the incredible parent you want to be. When
            your child sees you ask for help, they'll learn how to ask for it
            when they need it, too. It will also help you create deeper
            connections with your family, friends, acquaintances, and broader
            community. Like a house that needs solid bedrock to support its
            members, you also need that solid foundation to support your family.
            These simple tips will help you do just that, so that you can
            enhance the well-being of your children, the quality of your family
            life, and your full potential as a parent.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>View Other Articles</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adults and children sitting outside laughing and talking"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/making-connections/article/article1">
            <strong>Making and Modeling Healthy Social Connections</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mom and daughter meditating"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/managing-feelings/article/article2">
            <strong>Four Keys to Healthy Emotional Expression</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="family out on the park playing with balls"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/understanding-milestones/article/article4">
            <strong>Understanding Your Child's Milestones</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
