import React from "react";
import { Link } from "react-router-dom";
import PaginationCarousel from "../../components/PaginationCarousel/PaginationCarouselES";

// SASS
import "./About.scss";

export default function About() {
  return (
    <div className="container-fluid pmw-about-wrapper">
      <div className="row pmw-about-r1 pmw-limit-width">
        <h2 className="pmw-mt25 pmw-mb25">Acerca de la iniciativa</h2>
        <p className="pmw-mb25">
          Encuentre herramientas y recursos útiles para ayudarle a ser el mejor
          padre para sus hijos. Elija un elemento de los siguientes temas para
          obtener más información.
        </p>
      </div>

      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col">
          <div className="accordion accordion-flush" id="aboutAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseOne"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseOne"
                >
                  <p className="mb-0">¿Qué es PEACH Parenting?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingOne"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      PEACH Parenting (Promoting Education, Awareness,
                      Connection, and Health) es un sitio web móvil ofrecido por
                      la sección de Prevención y Apoyo Comunitario de la
                      División de Servicios para la Familia y la Niñez. Utilice
                      PEACH Parenting para acceder a información y recursos
                      diseñados para ayudarle a convertirse en un padre más
                      fuerte. Aprenda formas de adaptarse al estrés respondiendo
                      de manera saludable para que pueda cuidar mejor a sus
                      hijos. Esto se llama resiliencia y aprenderá más sobre
                      cómo desarrollarla en este sitio web.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseTwo"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseTwo"
                >
                  <p className="mb-0">¿Qué es la resiliencia?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingTwo"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      La resiliencia es la capacidad de gestionar sus reacciones
                      al estrés para que pueda adaptarse y sobrellevar los
                      momentos difíciles. Aprenderá cómo convertirse en un padre
                      más resiliente en este sitio web y cómo esto impactará
                      positivamente en el bienestar de su familia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseThree"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseThree"
                >
                  <p className="mb-0">
                    ¿Por qué es importante la resiliencia para la crianza?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingThree"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      La resiliencia consiste en encontrar una forma de ser el
                      padre que quiere ser, sin importar lo que esté pasando a
                      su alrededor. Cuando tiene resiliencia, puede manejar
                      mejor el estrés y garantizar el bienestar de su familia.
                      Las investigaciones demuestran que los niños que crecen
                      con más resiliencia tienen mejores resultados en la
                      escuela, están físicamente más sanos y manejan mejor sus
                      emociones y relaciones. En este sitio, aprenderá la
                      importancia de la resiliencia en sus experiencias diarias.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseFour"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseFour"
                >
                  <p className="mb-0">
                    ¿Cuáles son algunas de las formas en las que puedo practicar
                    ser más resiliente?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingFour"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p>
                      Comience su viaje para convertirse en un padre fuerte y
                      resiliente conectándose con consejos, recursos y
                      orientación que le ayudarán durante el camino. Al igual
                      que una banda elástica que tiene la capacidad de estirarse
                      sin romperse, puede desarrollar la capacidad de estirarse
                      y crecer como padre explorando y utilizando estos recursos
                      según sus necesidades.
                    </p>
                    <p className="mb-0">
                      Con solo estar aquí, ya está en camino de convertirse en
                      un padre más empoderado, fuerte y resiliente. Recuerde que
                      el desarrollo de la resiliencia es su propio viaje
                      personal, y puede utilizar lo que sabe para guiar a sus
                      hijos en su propio viaje hacia la resiliencia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseFive"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseFive"
                >
                  <p className="mb-0">¿Qué aprenderé en este sitio web?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingFive"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      En este sitio, aprenderá a establecer conexiones más
                      fuertes, a manejar sus sentimientos, a encontrar ayuda
                      cuando la necesite y a descubrir información sobre cómo
                      crecen y aprenden los niños. Con estos recursos basados en
                      evidencia, aprenderá y practicará habilidades para
                      afrontar el estrés diario y recuperarse cuando las cosas
                      no vayan bien. Cuanto más se prepare para afrontar los
                      desafíos que conlleva ser padre, más prosperará toda su
                      familia. ¡Seamos resilientes juntos!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseSix"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseSix"
                >
                  <p className="mb-0">¿Cómo aprenderé a ser resiliente?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingSix"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p>
                      Podrá aprender sobre las herramientas de resiliencia de
                      distintas formas en este sitio web, dependiendo de cuánto
                      tiempo tenga para aprender. Usted elige lo que desea
                      aprender y de qué forma.
                    </p>
                    <p>
                      <strong>UNO.</strong> si tiene un minuto, lea consejos
                      rápidos y de alto nivel para el área temática que haya
                      seleccionado.
                    </p>
                    <p>
                      <strong>TRES.</strong> si solo tiene tres minutos, lea
                      consejos rápidos y un artículo sobre el tema seleccionado.
                    </p>
                    <p className="mb-0">
                      <strong>CINCO.</strong> si tiene cinco minutos, lea los
                      consejos rápidos, consulte el artículo y complete una
                      actividad en la página del tema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseSeven"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseSeven"
                >
                  <p className="mb-0">
                    ¿Qué sucede si quiero más ayuda o información que no esté
                    disponible aquí?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingSeven"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      Siempre puede ir al{" "}
                      <Link to="/es/resource-center">Centro de Recursos</Link>{" "}
                      y, en cada una de las cinco páginas temáticas, encontrará
                      información adicional y enlaces a recursos útiles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25 text-center">Topic Areas</h2>
        </div>
      </div>

      <div className="row pmw-about-r3 pmw-mb75 pmw-limit-width">
        <div className="col carousel-wrapper1">
          <PaginationCarousel></PaginationCarousel>
        </div>
      </div>
    </div>
  );
}
