import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article4.scss";

import mainImg from "../../../../assets/img/article/article4/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article4/02_Practice-Positive-Parenting.jpg";
import sect2Img from "../../../../assets/img/article/article4/03_Celebrate-Childs-Progress.jpg";
import sect3Img from "../../../../assets/img/article/article4/04_Screen-and_Support.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";

export default function Article4() {
  return (
    <div className="container-fluid pmw-article4-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Cómo Comprender Los Hitos De Sus Hijos</h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          {/* <div
            className="hero-img"
            style={{ backgroundImage: `url(${mainImg})` }}
          >
            <span className="sr-only">Hero image</span>
          </div> */}
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="familia en el parque jugando con pelotas"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            Ver a sus hijos aprender a caminar, crear relaciones y desarrollar
            sus personalidades únicas es un proceso increíble. Esto es lo que
            llamamos “desarrollo infantil”, y es posible que haya notado que el
            comportamiento de sus hijos se vuelve más complejo a medida que
            crecen. Los niños tienen ciertos comportamientos y habilidades que
            desarrollan según su edad. Aunque sus hijos son únicos, la mayoría
            de los niños comparten los mismos hitos. Conocerlos puede darle una
            idea de qué esperar a medida que sus hijos crecen. Estos hitos
            incluyen nuevas formas de comunicarse, resolver problemas e
            interactuar con los demás.
          </p>
          <p className="pmw-mb25">
            Estos son algunos consejos para detectar y fomentar el desarrollo
            saludable de sus hijos.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Conozca los hitos del desarrollo infantil, y observe y celebre el
          progreso de sus hijos.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="padre leyendo un libro para dos hijas en el parque"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Si está al tanto de las habilidades que la mayoría de los niños
            pueden tener a ciertas edades, podrá hacer un seguimiento de cómo se
            desarrollan sus hijos de acuerdo con las etapas que estén
            transitando en la vida. Comprenderá cuándo sus expectativas se
            ajustan a la edad de sus hijos y cuándo está esperando demasiado de
            sus hijos en crecimiento. Por ejemplo, saber a qué edad la mayoría
            de los niños aprenden a ir al baño le permitirá no exigirse tanto si
            intenta enseñarles demasiado pronto. De esta forma, podrá cuidarlos
            mucho mejor, ya que sabrá celebrar cuando las cosas vayan bien y
            tendrá las herramientas adecuadas para detectar problemas desde el
            principio.
          </p>
          <p className="pmw-mb25">
            Si tiene alguna inquietud acerca de los hitos de sus hijos,
            comuníquese con el pediatra. Se asegurará de que todo esté bien o le
            ayudará a lidiar con los problemas que le estén preocupando. Les
            dará a sus hijos el apoyo que necesitan lo antes posible para que
            puedan prosperar.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Implemente una crianza positiva fomentando el crecimiento de sus
          hijos.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="niño practicando caminar con madre y padre en la sala de estar"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Hay un par de maneras en las que puede ayudar a sus hijos a
            desarrollarse de manera saludable en diferentes fases de sus vidas.
            Por ejemplo, puede implementar una combinación adecuada de cuidado,
            protección y apoyo. Esto les permitirá a sus hijos estar sanos y
            seguros y tener éxito.
          </p>
          <p>
            Cuando sus hijos son pequeños, es importante pasar tiempo
            hablándoles, leyéndoles, jugando y acurrucándose con ellos. Los
            elogios, las palabras de aliento y las reglas coherentes son muy
            importantes a esta edad. También lo es ayudarlos con el desarrollo
            del lenguaje, la resolución de problemas y la exploración de su
            entorno.
          </p>
          <hr />
          <p className="callout">
            Una crianza positiva facilita que sus hijos tengan éxito en la
            escuela, en el trabajo y en todas las etapas de sus vidas.
          </p>
          <hr />
          <p>
            A medida que crezcan e ingresen en la adolescencia, puede ayudarlos
            a ser más independientes apoyando sus buenas elecciones y
            ayudándolos a crear relaciones saludables. Pregúnteles cosas y
            escúchelos con atención para comprender sus relaciones con la
            familia, los amigos y los maestros. Aconséjelos si lo necesitan.
            Ayúdeles a comprender los cimientos de una relación saludable, como
            la honestidad, la confianza y el respeto. Estas habilidades y
            expectativas relacionadas con los demás les permitirán construir
            relaciones seguras y afectuosas en el futuro.
          </p>
          <p className="pmw-mb25">
            Una crianza positiva facilita que sus hijos tengan éxito en la
            escuela, en el trabajo y en todas las etapas de sus vidas.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Complete una evaluación y apoye a sus hijos según sus necesidades.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="padre e hija hablando con un medico"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Al igual que un dentista o un oftalmólogo se asegura de que los
            dientes y la visión de sus hijos estén saludables, un examen de
            detección realizado por el pediatra le garantizará que sus hijos
            están encaminados con respecto a su lenguaje y sus habilidades
            sociales, conductuales y motrices, y detectar si hay algún problema.
            A veces, la edad en la que los niños alcanzan estos hitos puede
            variar, por lo que no siempre habrá un problema. Su proveedor de
            atención médica puede ayudarle a entender si debe abordar un
            problema relacionado con un ligero retraso o si todo está bien.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Al amar y cuidar a sus hijos en crecimiento, conocer sus hitos puede
            ayudarle a comprender mucho más sobre cómo están aprendiendo,
            creciendo y avanzando a lo largo de sus vidas. Podrá proporcionarles
            un mejor apoyo, construir una relación más sólida y aprender las
            mejores herramientas para responder rápidamente a sus necesidades.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>Ver otros artículos</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adultos y niños sentados afuera riendo y hablando"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/making-connections/article/article1">
            <strong>
              Cómo Establecer y Modelar Conexiones Sociales Saludables
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mamá e hija meditando"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/managing-feelings/article/article2">
            <strong>
              Cuatro Claves para Expresar las Emociones de Forma Saludable
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <img
              src={article3Img}
              alt="padre y partidario abrazándose unos a otros"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <strong>Cómo Encontrar y Asegurar Ayuda Cuando la Necesita</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
