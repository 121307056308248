import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBack } from "use-back";
import $ from "jquery";

// SASS
import "./Header.scss";

import headerLogoWhite from "../../assets/img/logo/PEACH-Parenting-Logo_Primary_Small_White_Peach-Parenting-Logo_Horizontal_Small_White.png";
import headerLogoColor from "../../assets/img/logo/PEACH-Parenting-Logo_Primary_Small_White_Peach-Parenting-Logo_Horizontal_Small_Color.png";

export default function Header() {
  const location = useLocation();
  let navbarBg = "white-bg";
  const navigate = useNavigate();
  const { hasBack, handleBack } = useBack();
  // const history = useHistory();
  // console.log(location.key);
  // const hasBack = location.key !== undefined;

  const menuBtn = () => {
    if (location.pathname !== "/es/menu") {
      navigate("/es/menu");
    } else {
      navigate("/es/");
    }
  };

  const logoPath = {
    white: require("../../assets/img/logo/PEACH-Parenting-Logo_Primary_Small_White_Peach-Parenting-Logo_Horizontal_Small_White.png"),
    color: require("../../assets/img/logo/PEACH-Parenting-Logo_Primary_Small_White_Peach-Parenting-Logo_Horizontal_Small_Color.png"),
  };

  if (location.pathname === null || location.pathname === "/es/") {
    navbarBg = "white-bg";
  } else if (
    location.pathname === "/es/about" ||
    location.pathname === "/es/menu"
  ) {
    navbarBg = "orange-btn-bg";
  } else if (location.pathname === "/es/resource-center") {
    navbarBg = "dark-lime-bg";
  } else if (
    location.pathname === "/es/making-connections" ||
    location.pathname === "/es/making-connections/article/article1" ||
    location.pathname === "/es/making-connections/infographic"
  ) {
    navbarBg = "green-btn-bg";
  } else if (
    location.pathname === "/es/managing-feelings" ||
    location.pathname === "/es/managing-feelings/article/article2"
  ) {
    navbarBg = "purple-btn-bg";
  } else if (
    location.pathname === "/es/seeking-and-accepting-help" ||
    location.pathname === "/es/seeking-and-accepting-help/article/article3"
  ) {
    navbarBg = "dark-teal-bg";
  } else if (
    location.pathname === "/es/understanding-milestones" ||
    location.pathname === "/es/understanding-milestones/article/article4" ||
    location.pathname === "/es/understanding-milestones/milestones"
  ) {
    navbarBg = "pink-btn-bg";
  }

  var previousScroll = 0;
  $(window).scroll(function () {
    var currentScroll = $(this).scrollTop();
    if (currentScroll < 10) {
      showNav();
    } else if (
      currentScroll > 0 &&
      currentScroll < $(document).height() - $(window).height()
    ) {
      if (currentScroll > previousScroll) {
        hideNav();
      } else {
        showNav();
      }
      previousScroll = currentScroll;
    }
  });

  function hideNav() {
    $(".pmw-header").removeClass("is-visible").addClass("is-hidden");
  }

  function showNav() {
    $(".pmw-header")
      .removeClass("is-hidden")
      .addClass("is-visible")
      .addClass("scrolling");
  }

  return (
    <div className={`container-fluid pmw-header sticky-top ${navbarBg}`}>
      <div className="row">
        <div className="col-10 left-col">
          <Link className="" to="/es/">
            <img
              src={
                location.pathname === "/es/understanding-milestones" ||
                location.pathname ===
                  "/es/understanding-milestones/article/article4" ||
                location.pathname ===
                  "/es/understanding-milestones/milestones" ||
                location.pathname === "/es/seeking-and-accepting-help" ||
                location.pathname ===
                  "/es/seeking-and-accepting-help/article/article3" ||
                location.pathname === "/es/managing-feelings" ||
                location.pathname ===
                  "/es/managing-feelings/article/article2" ||
                location.pathname === "/es/making-connections" ||
                location.pathname ===
                  "/es/making-connections/article/article1" ||
                location.pathname === "/es/making-connections/infographic" ||
                location.pathname === "/es/resource-center" ||
                location.pathname === "/es/about" ||
                location.pathname === "/es/menu"
                  ? logoPath.white
                  : logoPath.color
              }
              alt="Peach Parenting Logo"
              className="pmw-header-logo"
            />
          </Link>
        </div>
        <div className="col-2 right-col text-center">
          <button className="menu-toggler" onClick={menuBtn}>
            {location.pathname === "/es/menu" ? (
              <i className="fal fa-home"></i>
            ) : (
              <i className="fal fa-bars"></i>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
