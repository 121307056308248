import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Milestones.scss";

import fullList from "../../../../assets/img/milestones/View-Full-List.png";
import year1 from "../../../../assets/img/milestones/1-Year.png";
import year2 from "../../../../assets/img/milestones/2-Years.png";
import year3 from "../../../../assets/img/milestones/3-Years.png";
import year4 from "../../../../assets/img/milestones/4-Years.png";
import year5 from "../../../../assets/img/milestones/5-Years.png";
import month2 from "../../../../assets/img/milestones/2-Months.png";
import month4 from "../../../../assets/img/milestones/4-Months.png";
import month6 from "../../../../assets/img/milestones/6-Months.png";
import month9 from "../../../../assets/img/milestones/9-Months.png";
import month15 from "../../../../assets/img/milestones/15-Months.png";
import month18 from "../../../../assets/img/milestones/18-Months.png";
import month30 from "../../../../assets/img/milestones/30-Months.png";

export default function Milestones() {
  return (
    <div className="container-fluid pmw-milestones-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Moving Through the Milestones</h2>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            As your child grows, it's helpful to observe how they play, learn,
            speak, act, and move. This will help you understand which milestones
            they've reached, and make sure they're on track with children their
            age. Use this infographic to help you do that. Keep your child's
            doctor up-to-date about your child's milestones, too.
          </p>
        </div>
      </div>
      <div className="row hop-wrapper navy-bg">
        <div className="col sect-p50 pmw-limit-width">
          <div className="row justify-content-center hop-r1">
            <div className="hop-top text-center">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/index.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={fullList}
                  alt="view full list"
                  className="img-fluid hop-top-img"
                />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r2">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-2mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month2} alt="2 months" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-4mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month4} alt="4 months" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r3">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-6mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month6} alt="6 months" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r4">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-9mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month9} alt="9 months" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-1yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year1} alt="1 year" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r5">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-15mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month15} alt="15 months" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r6">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-18mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month18} alt="18 months" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-2yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year2} alt="2 years" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r7">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-30mo.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={month30} alt="30 months" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r8">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-3yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year3} alt="3 years" className="img-fluid" />
              </a>
            </div>
            <div className="hop-col col2">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-4yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year4} alt="4 years" className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row justify-content-center hop-r9">
            <div className="hop-col col1">
              <a
                href="https://www.cdc.gov/ncbddd/actearly/milestones/milestones-5yr.html"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={year5} alt="5 years" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
