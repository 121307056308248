import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing3.scss";

import MainHeader from "../../../../components/MainHeader/MainHeaderES";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreviewES";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayerES";

import heroImg from "../../../../assets/img/main/Seeking-Help.png";
import headingIcon from "../../../../assets/img/icon/Seeking-Help-Icon.png";
import articleImg from "../../../../assets/img/article/article3/01_Main-Image.jpg";

import mapImg from "../../../../assets/img/main/Map.png";

export default function Landing3() {
  return (
    <>
      <MainHeader
        heading1={"Cómo Buscar y"}
        heading2={"Aceptar Ayuda"}
        icon={headingIcon}
        iconAlt={"cono de búsqueda y aceptación de ayuda"}
        heroImg={heroImg}
        heroImgAlt={"mujer sosteniendo un paraguas para mamá e hija"}
        heroImgClass={"light-teal-bg"}
        tips={true}
        article={true}
        audio={true}
        map={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Cuando está dando lo mejor de sí, puede resultar difícil pedir
              ayuda cuando la necesita &#8212; pero tener apoyo social es una
              inversión importante para usted y su familia. Aprenda a
              identificar a las personas de su red que pueden ayudar a que toda
              su familia prospere y a ponerse en contacto con ellas.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-teal-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Consejos Rápidos</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>Estos son algunos consejos para solicitar y recibir apoyo.</p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Reflexionar y aclarar.</strong> piense por qué puede ser
              que le cuesta pedir ayuda. Aclare qué tipo de apoyo necesita.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Identifique apoyos concretos.</strong> piense en tareas
              fáciles y posibles para las personas a las que desea pedirles
              ayuda. Dedique tiempo para comunicarse con ellas. Esto le
              redituará más tiempo en el futuro.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Conéctese con confianza.</strong> póngase en contacto con
              las personas de su comunidad para que le den una mano y haga un
              seguimiento de ser necesario.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Artículo</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Cómo Encontrar y Asegurar Ayuda Cuando la Necesita"}
              img={articleImg}
              imgAlt={"padre y partidario abrazándose unos a otros"}
              desc={
                "Ser padre implica cumplir muchas funciones que van más allá de cuidar a los hijos. Los padres planifican y organizan eventos y los hacen posibles. Preparan comidas con anticipación, organizan actividades creativas y arreglan rápido las cosas cuando se rompen. Están presentes cada día para sus hijos de muchas maneras diferentes. Estas son algunas formas..."
              }
              link={"/es/seeking-and-accepting-help/article/article3"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="audio">
          <div className="col-12">
            <h2 className="pmw-mb25">Clip de Audio</h2>
            <p className="pmw-mb25">
              Utilice esta breve grabación de audio para reflexionar sobre sus
              experiencias con pedirles ayuda a las personas en las que confía y
              cómo pueden ayudarle ahora mismo. Después de esta actividad,
              tendrá las herramientas adecuadas para actuar y encontrar apoyo
              para que su familia pueda crecer fuerte y saludable.
            </p>
          </div>
        </div>
        <div className="row pmw-mb25 pmw-limit-width justify-content-center">
          <AudioPlayer></AudioPlayer>
        </div>
        <div className="row pmw-limit-width" id="local-services">
          <div className="col-12">
            <h2 className="pmw-mb25">Servicios Locales</h2>
            <p className="pmw-mb25">
              Con solo un clic o una llamada telefónica, obtendrá la ayuda que
              necesita. Utilice este recurso para buscar servicios gratuitos y
              de bajo costo cerca de usted, como atención médica, alimentos,
              cuidado de los niños, asistencia financiera, capacitación laboral
              y más.
            </p>
            <div className="text-center map-box pmw-mb50">
              <img src={mapImg} alt="imagen de mapa" className="" />
              <a
                href="https://dfcs.georgia.gov/find-location"
                target={"_blank"}
                rel="noreferrer"
                className="pmw-btn dark-teal"
              >
                Encontrar Servicios Locales&nbsp;&nbsp;
                <i className="far fa-external-link"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item dark-teal">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button dark-teal collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Otros recursos sobre cómo buscar y aceptar ayuda
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content dark-teal">
                      <p>
                        <a
                          href="https://www.childhelp.org/contact/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Obtenga apoyo confidencial para el estrés de ser
                          padre&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.risemagazine.org/wp-content/uploads/2015/07/Its-OK-to-need-support-1.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Lea historias de padres que obtienen el apoyo que
                          necesitan&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
