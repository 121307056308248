import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./ArticlePreview.scss";

export default function ArticlePreview({ title, img, imgAlt, desc, link }) {
  return (
    <>
      <div className="row pmw-articlepreview-r1">
        <div className="col-12 pmw-mb25 px-0">
          <Link className="" to={link}>
            <img src={img} alt={imgAlt} className="img-fluid" />
          </Link>
        </div>
        <div className="col-12">
          <h3 className="pmw-mb25">{title}</h3>
          <p>{desc}</p>
        </div>
        <div className="col-12 pmw-mb15 link-box">
          <Link className="" to={link}>
            READ MORE&nbsp;&nbsp;<i className="fas fa-angle-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
}
