import React, { useEffect, useRef } from "react";

// SASS
// import "./Part1.scss";

// COMPONENTS
import Step from "../Step/Step";

// HELPERS
import animate from "../../utils/animate";

// LIBRARIES
import scrollama from "scrollama";

// ASSETS

// import floor1Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Bedroom.png";
// import floor2Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Childs-Room.png";
// import floor3Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Kitchen.png";
// import floor4Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Living-Room.png";
// import floor5Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Outside.png";
// import floor6Img from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Sidewalk.png";

import floorImg from "../../../../../../assets/img/infographic/Making-Connections-Infographic_Entire-Floor-Plan.png";

export default function Part1({ step }) {
  // SCROLLAMA INSTANCE
  const scrolly = scrollama();

  // ANIMATION STEP DATA
  const animationSequence = [
    { stepNumber: "0", height: null, description: "spacer" },
    {
      stepNumber: "1",
      height: null,
      description: "scroll to floor2 Img, fade out desc1, fade in desc2",
    },
    {
      stepNumber: "2",
      height: null,
      description: "scroll to floor3 Img, fade out desc2, fade in desc3",
    },
    {
      stepNumber: "3",
      height: null,
      description: "scroll to floor4 Img, fade out desc3, fade in desc4",
    },
    {
      stepNumber: "4",
      height: null,
      description: "scroll to floor5 Img, fade out desc4, fade in desc5",
    },
    {
      stepNumber: "5",
      height: null,
      description: "scroll to floor6 Img, fade out desc5, fade in desc6",
    },
    {
      stepNumber: "6",
      height: null,
      description: "spacer",
    },
  ];

  // REFS
  const floorEl = useRef(null);
  const floorDesc1El = useRef(null);
  const floorDesc2El = useRef(null);
  const floorDesc3El = useRef(null);
  const floorDesc4El = useRef(null);
  const floorDesc5El = useRef(null);
  const floorDesc6El = useRef(null);

  // RUN WHEN THE COMPONENT MOUNTS
  useEffect(() => {
    scrolly
      .setup({
        step: `.${step}`,
        debug: false,
        threshold: 1,
        progress: true,
      })
      .onStepEnter(({ element, direction }) => {
        const step = element.dataset.step;
        switch (step) {
          case "1": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc0",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc1", direction);
            animate.fadeOutOnEnter(floorDesc1El.current, direction);
            animate.fadeInOnEnter(floorDesc2El.current, direction);
            break;
          }
          case "2": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc1",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc2", direction);
            animate.fadeOutOnEnter(floorDesc2El.current, direction);
            animate.fadeInOnEnter(floorDesc3El.current, direction);
            break;
          }
          case "3": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc2",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc3", direction);
            animate.fadeOutOnEnter(floorDesc3El.current, direction);
            animate.fadeInOnEnter(floorDesc4El.current, direction);
            break;
          }
          case "4": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc3",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc4", direction);
            animate.fadeOutOnEnter(floorDesc4El.current, direction);
            animate.fadeInOnEnter(floorDesc5El.current, direction);
            break;
          }
          case "5": {
            animate.removeClassOnEnter(
              floorEl.current,
              "floor-loc4",
              direction
            );
            animate.addClassOnEnter(floorEl.current, "floor-loc5", direction);
            animate.fadeOutOnEnter(floorDesc5El.current, direction);
            animate.fadeInOnEnter(floorDesc6El.current, direction);
            break;
          }
          default:
            break;
        }
      })
      .onStepProgress(({ element, progress }) => {
        const step = element.dataset.step;
        switch (step) {
          default:
            break;
        }
      })
      .onStepExit(({ element, direction }) => {
        const step = element.dataset.step;
        switch (step) {
          case "1": {
            animate.addClassOnExit(floorEl.current, "floor-loc0", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc1", direction);
            animate.fadeInOnExit(floorDesc1El.current, direction);
            animate.fadeOutOnExit(floorDesc2El.current, direction);
            break;
          }
          case "2": {
            animate.addClassOnExit(floorEl.current, "floor-loc1", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc2", direction);
            animate.fadeInOnExit(floorDesc2El.current, direction);
            animate.fadeOutOnExit(floorDesc3El.current, direction);
            break;
          }
          case "3": {
            animate.addClassOnExit(floorEl.current, "floor-loc2", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc3", direction);
            animate.fadeInOnExit(floorDesc3El.current, direction);
            animate.fadeOutOnExit(floorDesc4El.current, direction);
            break;
          }
          case "4": {
            animate.addClassOnExit(floorEl.current, "floor-loc3", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc4", direction);
            animate.fadeInOnExit(floorDesc4El.current, direction);
            animate.fadeOutOnExit(floorDesc5El.current, direction);
            break;
          }
          case "5": {
            animate.addClassOnExit(floorEl.current, "floor-loc4", direction);
            animate.removeClassOnExit(floorEl.current, "floor-loc5", direction);
            animate.fadeInOnExit(floorDesc5El.current, direction);
            animate.fadeOutOnExit(floorDesc6El.current, direction);
            break;
          }
          default:
            break;
        }
      });
  }, [scrolly, step]);

  // CUSTOM FUNCTIONS
  return (
    <>
      <section className="wrapper">
        <div className="sticky-container sticky-container__1">
          {/* STICKY */}
          <div className="part1">
            <div className="floor-container grass-green-bg">
              <div className="floor-loc0" ref={floorEl}>
                <div className="floor">
                  <img
                    src={floorImg}
                    alt="vista superior del dormitorio, el baño, la habitación del niño, la cocina, la sala de estar, el patio delantero, la acera
                    y la calle en forma vertical"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="desc-container green-btn-bg">
              <div className="desc desc-1" ref={floorDesc1El}>
                <p className="desc-title">Dormitorio y Baño</p>
                <ul className="desc-bullet">
                  <li>Cuide su higiene personal.</li>
                  <li>Empiece a escribir un diario.</li>
                  <li>
                    Piense tres cosas del día que le hayan hecho sentir
                    gratitud.
                  </li>
                  <li>Duerma, al menos, entre 7 y 9 horas por la noche.</li>
                </ul>
              </div>
              <div className="desc desc-2" ref={floorDesc2El}>
                <p className="desc-title">Habitación de los niños</p>
                <ul className="desc-bullet">
                  <li>
                    Cree un espacio para leer, dibujar, jugar y realizar
                    actividades significativas.
                  </li>
                  <li>Únase a la diversión.</li>
                  <li>Hable con sus hijos.</li>
                  <li>Escuche atentamente.</li>
                  <li>Exploren juntos sus sentimientos.</li>
                </ul>
              </div>
              <div className="desc desc-3" ref={floorDesc3El}>
                <p className="desc-title">Cocina</p>
                <ul className="desc-bullet">
                  <li>Prepare y coma comidas nutritivas.</li>
                  <li>
                    Construya rutinas y hábitos saludables, como limpiar y lavar
                    los platos.
                  </li>
                  <li>
                    No solo usen la mesa para comer: ¡hagan los deberes y
                    jueguen juntos!
                  </li>
                </ul>
              </div>
              <div className="desc desc-4" ref={floorDesc4El}>
                <p className="desc-title">Sala de estar</p>
                <ul className="desc-bullet">
                  <li>
                    Hable con su familia sobre las cosas que van bien y las
                    formas de apoyarse mutuamente para que todos se sientan
                    cuidados.
                  </li>
                  <li>
                    Organice una noche de juegos de mesa por semana para pasar
                    tiempo de calidad juntos.
                  </li>
                  <li>
                    Miren un programa de televisión o una película para toda la
                    familia. Pregúnteles a sus hijos qué les gustó de la
                    historia y por qué.
                  </li>
                </ul>
              </div>
              <div className="desc desc-5" ref={floorDesc5El}>
                <p className="desc-title">Exterior</p>
                <ul className="desc-bullet">
                  <li>Pase tiempo en el exterior con su familia y amigos.</li>
                  <li>
                    Disfruten juntos las distintas actividades, como jardinería,
                    picnics y deportes.
                  </li>
                  <li>
                    Comparta con sus hijos las tareas cotidianas y
                    explíqueselas: trabajen en el jardín y armen proyectos al
                    aire libre.
                  </li>
                </ul>
              </div>
              <div className="desc desc-6" ref={floorDesc6El}>
                <p className="desc-title">Acera del vecindario</p>
                <ul className="desc-bullet">
                  <li>Conéctese con sus vecinos y pregúnteles cómo les va.</li>
                  <li>
                    Dé una mano: ayúdelos a trabajar en sus jardines y présteles
                    sus herramientas.
                  </li>
                  <li>
                    Salga a caminar y pasee en bicicleta con sus hijos.
                    Construya conexiones más sólidas con su comunidad.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* STEPS */}
        {animationSequence.map(
          ({ stepNumber, height, description, offset }) => {
            return (
              <Step
                stepSection={step}
                stepNumber={stepNumber}
                stepDescription={description}
                height={height}
                key={stepNumber}
                offset={offset}
              />
            );
          }
        )}
      </section>
    </>
  );
}
