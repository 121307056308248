import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing4.scss";

import MainHeader from "../../../../components/MainHeader/MainHeaderES";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreviewES";

import heroImg from "../../../../assets/img/main/Understanding-Milestones.png";
import headingIcon from "../../../../assets/img/icon/Understanding-Milestones-Icon.png";
import articleImg from "../../../../assets/img/article/article4/01_Main-Image.jpg";
import hopImg from "../../../../assets/img/main/Milestones.png";

export default function Landing4() {
  return (
    <>
      <MainHeader
        heading1={"Cómo Comprender"}
        heading2={"Los Hitos"}
        icon={headingIcon}
        iconAlt={"Ícono de comprensión de los hitos"}
        heroImg={heroImg}
        heroImgAlt={"niño creciendo de niño a adulto"}
        heroImgClass={"light-pink-bg"}
        tips={true}
        article={true}
        milestones={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Como cuidador, es emocionante ver a sus hijos hacer cosas nuevas a
              medida que crece. Comprender los signos y las señales importantes
              a los que debe prestar atención a medida que sus hijos crecen le
              permite darles todo su apoyo y le ayuda a detectar problemas que
              podrían necesitar atención y cuidado adicionales.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-pink-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Consejos Rápidos</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>
              Conozca las herramientas que puede usar para alimentar el
              desarrollo saludable de sus hijos a medida que crecen.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Conozca los hitos.</strong> Obtenga información sobre los{" "}
              <a
                href="https://www.cdc.gov/ncbddd/actearly/index.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Factearly%2Findex.html"
                target={"_blank"}
                rel="noreferrer"
              >
                hitos del desarrollo infantil&nbsp;
                <sup>
                  <i className="far fa-external-link"></i>
                </sup>
              </a>
              , y haga un seguimiento del progreso de sus hijos a medida que
              crecen. Celebre cuando sus hijos alcancen estos hitos y haga algo
              al respecto si tiene alguna inquietud.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Implemente una crianza positiva.</strong> el desarrollo
              saludable de los niños es el resultado de una crianza positiva.
              Puede crear rutinas y hábitos que ayuden a sus hijos a aprender y
              crecer, como leer juntos o compartir una comida. Asegúrese de que
              estén sanos y seguros, y actúe con calidez y sensibilidad al
              cuidarlos.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Complete una evaluación y bríndeles apoyo.</strong> haga
              un seguimiento con el pediatra acerca de cualquier inquietud que
              tenga sobre el aprendizaje, el comportamiento o el crecimiento de
              sus hijos. Una evaluación les permitirá a usted y a su proveedor
              de atención médica reconocer y abordar cualquier problema.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Artículo</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Cómo Comprender Los Hitos De Sus Hijos"}
              img={articleImg}
              imgAlt={"familia en el parque jugando con pelotas"}
              desc={
                "Ver a sus hijos aprender a caminar, crear relaciones y desarrollar sus personalidades únicas es un proceso increíble. Esto es lo que llamamos “desarrollo infantil”, y es posible que haya notado que el comportamiento de sus hijos se vuelve más complejo a medida que crecen. Los niños tienen ciertos comportamientos y habilidades que desarrollan..."
              }
              link={"/es/understanding-milestones/article/article4"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="milestones">
          <div className="col-12">
            <h2 className="pmw-mb25">Hitos</h2>
            <p className="pmw-mb25">
              ¿Conoce los hitos del desarrollo infantil? Las nuevas habilidades
              que sus hijos desarrollan con el tiempo &#8212; como gatear,
              hablar o caminar &#8212; se denominan hitos del desarrollo.
              Obtenga más información sobre qué esperar a medida que sus hijos
              crecen.
            </p>
          </div>
        </div>

        <div className="row pmw-limit-width">
          <div className="text-center hop-box pmw-mb50">
            <img src={hopImg} alt="imagen de rayuela" className="" />
            <Link
              className="pmw-btn pink"
              to="/es/understanding-milestones/milestones"
            >
              Ver Los Hitos Del Desarrollo
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item pink">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button pink collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Otros Recursos Sobre la Compremsión de los Hitos
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content pink">
                      <p>
                        <a
                          href="https://development.decal.ga.gov/#/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Haga un seguimiento de los ciclos de su hijo en su
                          edad&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Obtenga consejos positivos para padres&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://helpmegrowmn.org/HMG/HelpfulRes/Articles/HowEncourageBrainDev/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Fortalezca el cerebro de su hijo con estas
                          actividades&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.youtube.com/watch?v=rby1JRUD2KU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=46"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Sepa qué esperar para el desarrollo emocional de su
                          hijo&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
