import { useState, useRef, useEffect } from "react";

const useAudio = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMute, setIsMute] = useState(false);
  const [volumeActive, setVolumeActive] = useState(false);
  const [audioVoice, setAudioVoice] = useState(true);

  // reference
  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();
  const volumeBar = useRef();

  const onLoadedMetadata = () => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  };

  const onTrackChange = (source) => {
    // console.log("there");
    setAudioVoice(source);
    setIsPlaying(false);
    audioPlayer.current.pause();
    audioPlayer.current.load();
  };

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);

    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changeVolume = () => {
    audioPlayer.current.volume = volumeBar.current.value / 100;
    if (audioPlayer.current.volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    }
  };

  const toggleVolume = () => {
    const prevValue = isMute;
    setIsMute(!prevValue);

    if (!prevValue) {
      audioPlayer.current.volume = 0;
      volumeBar.current.value = 0;
      changePlayerVolume();
    } else {
      audioPlayer.current.volume = 0.5;
      volumeBar.current.value = 50;
      changePlayerVolume();
    }
  };

  const toggleVolumeCtrl = () => {
    const prevValue = volumeActive;
    setVolumeActive(!prevValue);
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const changePlayerVolume = () => {
    volumeBar.current.style.setProperty(
      "--seek-before-width",
      `${volumeBar.current.value}%`
    );
  };

  const audioEnded = () => {
    setIsPlaying(false);
  };

  return {
    isPlaying,
    duration,
    setDuration,
    currentTime,
    isMute,
    audioPlayer,
    progressBar,
    animationRef,
    volumeBar,
    calculateTime,
    togglePlayPause,
    changeRange,
    changeVolume,
    toggleVolume,
    audioEnded,
    onLoadedMetadata,
    audioVoice,
    setAudioVoice,
    onTrackChange,
    toggleVolumeCtrl,
    volumeActive,
  };
};

export { useAudio };
