import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing3.scss";

import MainHeader from "../../../../components/MainHeader/MainHeader";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreview";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";

import heroImg from "../../../../assets/img/main/Seeking-Help.png";
import headingIcon from "../../../../assets/img/icon/Seeking-Help-Icon.png";
import articleImg from "../../../../assets/img/article/article3/01_Main-Image.jpg";

import mapImg from "../../../../assets/img/main/Map.png";

export default function Landing3() {
  return (
    <>
      <MainHeader
        heading1={"Seeking and"}
        heading2={"Accepting Help"}
        icon={headingIcon}
        iconAlt={"Seeking and Accepting Help Icon"}
        heroImg={heroImg}
        heroImgAlt={"woman holding a umbrella for mom and daughter"}
        heroImgClass={"light-teal-bg"}
        tips={true}
        article={true}
        audio={true}
        map={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              When you're putting in your best effort, it might feel tough to
              ask for help when you need it &#8212; but having social support is
              an important investment in yourself and your family. Learn how to
              identify and reach out to people in your network so that your
              whole family can thrive.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-teal-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Quick Tips</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>Here are some tips on how to ask for and receive support.</p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Reflect and clarify.</strong> Consider why you might be
              hesitating to ask for help. Clarify what kind of support you need.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Identify concrete supports.</strong> Think about easy and
              doable tasks for people you're reaching out to. Plan some time to
              reach out to them. This will give you back even more time later
              on.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Connect with confidence.</strong> Reach out to let people
              in your community lend a hand and follow up if you need to.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Article</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Finding and Securing Help When You Need It"}
              img={articleImg}
              imgAlt={"parent and supporter hugging each other"}
              desc={
                "When you're a parent, you have many roles that go beyond caretaking for your child. You're an event planner, organizer, and executor. You're a meal planner, a creative organizer, and a quick fixer when things break.  You show up every day for your child in many different ways. Here are some ways..."
              }
              link={"/seeking-and-accepting-help/article/article3"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="audio">
          <div className="col-12">
            <h2 className="pmw-mb25">Audio Clip</h2>
            <p className="pmw-mb25">
              Use this short audio recording to reflect on your experiences with
              getting help from people you trust, and how they can help you
              right now. After this activity, you'll have the right tools to
              take action and find support, so your family can grow strong and
              healthy.
            </p>
          </div>
        </div>
        <div className="row pmw-mb25 pmw-limit-width justify-content-center">
          <AudioPlayer></AudioPlayer>
        </div>
        <div className="row pmw-limit-width" id="local-services">
          <div className="col-12">
            <h2 className="pmw-mb25">Local Services</h2>
            <p className="pmw-mb25">
              Getting the help you need is just one click or phone call away.
              Use this resource to search for free and low-cost services near
              you, like medical care, food, child care, financial assistance,
              job training, and more.
            </p>
            <div className="text-center map-box pmw-mb50">
              <img src={mapImg} alt="image of map" className="" />
              <a
                href="https://dfcs.georgia.gov/find-location"
                target={"_blank"}
                rel="noreferrer"
                className="pmw-btn dark-teal"
              >
                Find Local Services&nbsp;&nbsp;
                <i className="far fa-external-link"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item dark-teal">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button dark-teal collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Other Resources on Seeking and Accepting Help
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content dark-teal">
                      <p>
                        <a
                          href="https://www.childhelp.org/contact/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Get confidential support for the stresses of
                          parenting&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.risemagazine.org/wp-content/uploads/2015/07/Its-OK-to-need-support-1.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Read stories of parents getting the support they
                          need&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
