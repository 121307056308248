import React from "react";

// SASS
import "./Resources.scss";

export default function Resources() {
  return (
    <div className="container-fluid pmw-resources-wrapper pmw-limit-width">
      <div className="row pmw-resources-r1 pmw-pt50">
        <div className="col">
          <h2 className="pmw-mb25">All Resources</h2>
        </div>
      </div>
      <div className="row pmw-mb50">
        <div className="col">
          <div className="accordion accordion-flush" id="resourcepageAccordion">
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingOne"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseOne"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseOne"
                >
                  <p className="mb-0">Making Connections</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingOne"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.exchangefamilycenter.org/exchange-family-center-blog/2018/1/25/protective-factors-find-strong-support-among-your-social-connections"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Find strong support with social connections&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://community.whattoexpect.com/forums/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Discover a parent community group online&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://static1.squarespace.com/static/5552947de4b0471a840f9e27/t/5839ea6915d5db6612f542b7/1480190571654/CM-Booklet_Digital_v5.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Overcome tough times with healthy relationships&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.youtube.com/watch?v=tQI_3aUQCpU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=6"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Build a family support system&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingTwo"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseTwo"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseTwo"
                >
                  <p className="mb-0">Managing Feelings</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingTwo"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://cssp.org/wp-content/uploads/2018/08/HO-2.4-Social-Emotional-Skill-Building-worksheet.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Use these activities with your child to build emotional
                        skills&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.cdc.gov/parents/essentials/communication/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Learn how to build strong communication with your
                        child&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.mhanational.org/sites/default/files/BACK%20TO%20SCHOOL%202014%20-%20Healthy%20Mental%20and%20Emotional%20Development.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Know what to expect for your child's emotional
                        development&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingThree"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseThree"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseThree"
                >
                  <p className="mb-0">Seeking and Accepting Help</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingThree"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.childhelp.org/contact/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Get confidential support for the stresses of
                        parenting&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.risemagazine.org/wp-content/uploads/2015/07/Its-OK-to-need-support-1.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Read stories of parents getting the support they
                        need&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingFour"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseFour"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseFour"
                >
                  <p className="mb-0">Understanding Milestones</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingFour"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://development.decal.ga.gov/#/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Track your child's milestones according to their
                        age&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Get positive parenting tips from CDC&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://helpmegrowmn.org/HMG/HelpfulRes/Articles/HowEncourageBrainDev/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Make your child's brain stronger with these
                        activities&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.youtube.com/watch?v=rby1JRUD2KU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=46"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Know what to expect as your child grows&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingFive"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseFive"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseFive"
                >
                  <p className="mb-0">Keeping Your Child Healthy</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingFive"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.facebook.com/SPLASHga"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Learn the latest on water safety&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://dph.georgia.gov/immunization-section"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Stay up to date on vaccinations&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingSix"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseSix"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseSix"
                >
                  <p className="mb-0">More Resources</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingSix"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.bbbgeorgia.org/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Learn how your child's brain develops as it grows&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://positivepsychology.com/resilience-activities-worksheets/#exercises-resilience"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Build resilience for yourself and your child&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.resilientga.org/learning-cards"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Talk about resilience with learning cards&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
