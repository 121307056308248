import React from "react";
import { Route, Routes } from "react-router-dom";

// SASS
import "./Main4.scss";

import Landing4 from "./Landing4/Landing4ES";
import Article4 from "./Article4/Article4ES";
import Milestones from "./Milestones/MilestonesES";
import NotFound from "../../NotFound/NotFound";

export default function Main4() {
  return (
    <Routes>
      <Route path="" element={<Landing4 />} />
      <Route path="/article/article4" element={<Article4 />} />
      <Route path="/milestones" element={<Milestones />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
