import React from "react";

// SASS
import "./Resources.scss";

export default function Resources() {
  return (
    <div className="container-fluid pmw-resources-wrapper pmw-limit-width">
      <div className="row pmw-resources-r1 pmw-pt50">
        <div className="col">
          <h2 className="pmw-mb25">Todos Los Recursos</h2>
        </div>
      </div>
      <div className="row pmw-mb50">
        <div className="col">
          <div className="accordion accordion-flush" id="resourcepageAccordion">
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingOne"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseOne"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseOne"
                >
                  <p className="mb-0">Hacer Conexiones</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingOne"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.exchangefamilycenter.org/exchange-family-center-blog/2018/1/25/protective-factors-find-strong-support-among-your-social-connections"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Encuentre un fuerte apoyo con conexiones sociales&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://community.whattoexpect.com/forums/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Descubra un grupo comunitario de padres en línea&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://static1.squarespace.com/static/5552947de4b0471a840f9e27/t/5839ea6915d5db6612f542b7/1480190571654/CM-Booklet_Digital_v5.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Supere los momentos difíciles con relaciones sanas&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.youtube.com/watch?v=tQI_3aUQCpU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=6"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Construir un sistema de apoyo familiar&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingTwo"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseTwo"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseTwo"
                >
                  <p className="mb-0">Cómo Manejar los Sentimientos</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingTwo"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://cssp.org/wp-content/uploads/2018/08/HO-2.4-Social-Emotional-Skill-Building-worksheet.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Use estas actividades con su hijo para desarrollar
                        habilidades emocionales&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.cdc.gov/parents/essentials/communication/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Aprenda a construir una comunicación sólida con su
                        hijo&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.mhanational.org/sites/default/files/BACK%20TO%20SCHOOL%202014%20-%20Healthy%20Mental%20and%20Emotional%20Development.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Sepa qué esperar para el desarrollo emocional de su
                        hijo&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingThree"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseThree"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseThree"
                >
                  <p className="mb-0">Cómo Buscar y Aceptar Ayuda</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingThree"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.childhelp.org/contact/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Obtenga apoyo confidencial para el estrés de ser
                        padre&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.risemagazine.org/wp-content/uploads/2015/07/Its-OK-to-need-support-1.pdf"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Lea historias de padres que obtienen el apoyo que
                        necesitan&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingFour"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseFour"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseFour"
                >
                  <p className="mb-0">Cómo Comprender los Hitos</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingFour"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://development.decal.ga.gov/#/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Haga un seguimiento de los ciclos de su hijo en su
                        edad&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Obtenga consejos positivos para padres&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://helpmegrowmn.org/HMG/HelpfulRes/Articles/HowEncourageBrainDev/index.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Fortalezca el cerebro de su hijo con estas
                        actividades&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.youtube.com/watch?v=rby1JRUD2KU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=46"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Sepa qué esperar para el desarrollo emocional de su
                        hijo&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingFive"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseFive"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseFive"
                >
                  <p className="mb-0">Mantener a su Hijo Sano</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingFive"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.facebook.com/SPLASHga"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Conozca lo último sobre seguridad en el agua&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://dph.georgia.gov/immunization-section"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Manténgase al día con las vacunas&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item grass-green">
              <h2
                className="accordion-header"
                id="resourcepageAccordionHeadingSix"
              >
                <button
                  className="accordion-button grass-green collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#resourcepageAccordionCollapseSix"
                  aria-expanded="false"
                  aria-controls="resourcepageAccordionCollapseSix"
                >
                  <p className="mb-0">Más Recursos</p>
                </button>
              </h2>
              <div
                id="resourcepageAccordionCollapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="resourcepageAccordionHeadingSix"
                data-bs-parent="#resourcepageAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content grass-green">
                    <p>
                      <a
                        href="https://www.bbbgeorgia.org/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Aprenda cómo se desarrolla el cerebro de su hijo a
                        medida que crece&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://positivepsychology.com/resilience-activities-worksheets/#exercises-resilience"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Construye resiliencia para ti y tu hijo&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="https://www.resilientga.org/learning-cards"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Hable sobre resiliencia con tarjetas de
                        aprendizaje&nbsp;
                        <sup>
                          <i className="far fa-external-link"></i>
                        </sup>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
