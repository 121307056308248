import React from "react";

// SASS
import "./HelplineButton.scss";

export default function HelplineButton() {
  return (
    <div className="pmw-call-btn-wrapper">
      <div className="pmw-call-btn">
        <div>
          <a href="tel:18002445373" className="pmw-call-btn">
            <i className="fas fa-phone-alt"></i>
            <p className="mb-0">AYUDA</p>
          </a>
        </div>
      </div>
    </div>
  );
}
