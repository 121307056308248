import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing1.scss";

import MainHeader from "../../../../components/MainHeader/MainHeaderES";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreviewES";

import heroImg from "../../../../assets/img/main/Making-Connections.png";
import headingIcon from "../../../../assets/img/icon/Making-Connections-Icon.png";
import articleImg from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import infographicImg from "../../../../assets/img/infographic/Infographic_Thumbnail.png";

export default function Landing1() {
  return (
    <>
      <MainHeader
        heading1={"Cómo Hacer"}
        heading2={"Conexiones"}
        icon={headingIcon}
        iconAlt={"Ícono de Hacer Conexiones"}
        heroImg={heroImg}
        heroImgAlt={"Grupos de personal diversas apoyandonse mutualmente"}
        heroImgClass={"light-green-bg"}
        tips={true}
        article={true}
        infographic={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Tener una red de amigos, familiares y vecinos que le brinden su
              apoyo puede facilitar el cuidado de sus hijos. Debe mantener sus
              conexiones sociales, tanto grandes como pequeñas, para hacer
              crecer esta red. Las conexiones sociales son relaciones positivas
              en su vida que proporcionan apoyo emocional, consejos y comodidad.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-green-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Consejos Rápidos</h2>
            <span>1 MINUTO</span>
          </div>
          <div className="col-12">
            <p className="pmw-mb25">
              Siga estos pasos para encontrar, mantener y modelar conexiones
              sociales.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Priorizar y planificar.</strong> destine momentos cortos y
              que sean fáciles de gestionar para mantener o desarrollar sus
              relaciones cercanas y de apoyo. Haga un plan para ponerse en
              contacto con sus amigos y vecinos. Por ejemplo, puede escribir un
              recordatorio en su teléfono que le indique que debe llamar o
              enviar un mensaje de texto a un nuevo amigo de la escuela de sus
              hijos.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Conectarse con la comunidad.</strong> hay otras personas
              en su comunidad que están pasando por experiencias similares e
              hitos de crianza. Consulte los grupos comunitarios locales que
              traten los temas que le interesan y utilice el Centro de Recursos
              para averiguar cómo participar en ellos. Conéctese con otros
              padres en eventos en los que puedan conocerse.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Modelar relaciones positivas.</strong> cuando sus hijos
              vean que usted mantiene relaciones positivas, aprenderán lo que es
              una relación de apoyo. Esto los animará a construir sus propias
              relaciones positivas.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Artículo</h2>
            <span>3 MINUTOS</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Cómo Establecer y Modelar Conexiones Sociales Saludables"}
              img={articleImg}
              imgAlt={"adultos y niños sentados afuera riendo y hablando"}
              desc={
                "Si usted busca fuentes de apoyo emocional y desarrolla prácticas de cuidado personal, sus hijos podrán crecer sanos y felices. Conseguir el apoyo emocional que necesita puede convertirle en un padre más resiliente. Sus hijos necesitan apoyo. De la misma manera, confiar en las personas de su red para obtener diferentes tipos de apoyo puede.…"
              }
              link={"/es/making-connections/article/article1"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="infographic">
          <div className="col-12">
            <h2 className="pmw-mb25">Infografía</h2>
            <p className="pmw-mb25">
              Compartamos algunos datos sobre la importancia de las relaciones y
              las conexiones sociales.
            </p>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <img
              src={infographicImg}
              alt="imagen de una casa"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <Link
              className="pmw-btn green2"
              to="/es/making-connections/infographic"
            >
              Ver Infografica
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item green">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button green collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Otros recursos sobre cómo hacer conexiones
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content green">
                      <p>
                        <a
                          href="https://www.exchangefamilycenter.org/exchange-family-center-blog/2018/1/25/protective-factors-find-strong-support-among-your-social-connections"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Encuentre un fuerte apoyo con conexiones
                          sociales&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://community.whattoexpect.com/forums/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Descubra un grupo comunitario de padres en línea&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://static1.squarespace.com/static/5552947de4b0471a840f9e27/t/5839ea6915d5db6612f542b7/1480190571654/CM-Booklet_Digital_v5.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Supere los momentos difíciles con relaciones
                          sanas&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.youtube.com/watch?v=tQI_3aUQCpU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=6"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Construir un sistema de apoyo familiar&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
