import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing1.scss";

import MainHeader from "../../../../components/MainHeader/MainHeader";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreview";

import heroImg from "../../../../assets/img/main/Making-Connections.png";
import headingIcon from "../../../../assets/img/icon/Making-Connections-Icon.png";
import articleImg from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import infographicImg from "../../../../assets/img/infographic/Infographic_Thumbnail.png";

export default function Landing1() {
  return (
    <>
      <MainHeader
        heading1={"Making"}
        heading2={"Connections"}
        icon={headingIcon}
        iconAlt={"Making Connections Icon"}
        heroImg={heroImg}
        heroImgAlt={"Diverse group of people holding each other"}
        heroImgClass={"light-green-bg"}
        tips={true}
        article={true}
        infographic={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Having a network of supportive friends, family, and neighbors can
              make it easier to care for your children. Maintaining your social
              connections, big and small, can help grow this network. Social
              connections are positive relationships in your life that provide
              emotional support, advice, and comfort.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-green-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Quick Tips</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p className="pmw-mb25">
              Follow these steps to find, maintain, and model social
              connections.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Prioritize and plan.</strong> Carve out small,
              easy-to-manage chunks of time to maintain or grow close and
              supportive relationships. Make a plan to reach out to friends and
              neighbors. For instance, you might write a reminder in your phone
              to call or text a new friend from your child's school.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Connect with community.</strong> There are others in your
              community going through similar experiences and parenting
              milestones. Check out local community groups with topics that you
              are interested in, and use the resource center to find out how to
              get involved with these. Connect with other parents at events
              where you can get to know each other.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Model positive relationships.</strong> When you
              demonstrate positive relationships for your children, you teach
              them what a supportive relationship looks like. This will
              encourage them to build their own positive relationships.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Article</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Making and Modeling Healthy Social Connections"}
              img={articleImg}
              imgAlt={
                "adults and children sitting outside laughing and talking"
              }
              desc={
                "Your child can grow up healthy and happy when you have sources of emotional support and practices of self-care. Getting the emotional support you need can make you a more resilient parent.  Just like your child needs support, relying on people in your network for different kinds of support can.…"
              }
              link={"/making-connections/article/article1"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="infographic">
          <div className="col-12">
            <h2 className="pmw-mb25">Infographic</h2>
            <p className="pmw-mb25">
              Let's share some facts about the importance of relationships and
              social connections.
            </p>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <img
              src={infographicImg}
              alt="image of a house"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <Link
              className="pmw-btn green2"
              to="/making-connections/infographic"
            >
              View Infographic
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item green">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button green collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Other Resources on Making Connections
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content green">
                      <p>
                        <a
                          href="https://www.exchangefamilycenter.org/exchange-family-center-blog/2018/1/25/protective-factors-find-strong-support-among-your-social-connections"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Find strong support with social connections&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://community.whattoexpect.com/forums/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Discover a parent community group online&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://static1.squarespace.com/static/5552947de4b0471a840f9e27/t/5839ea6915d5db6612f542b7/1480190571654/CM-Booklet_Digital_v5.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Overcome tough times with healthy relationships&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.youtube.com/watch?v=tQI_3aUQCpU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=6"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Build a family support system&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
