import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article4.scss";

import mainImg from "../../../../assets/img/article/article4/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article4/02_Practice-Positive-Parenting.jpg";
import sect2Img from "../../../../assets/img/article/article4/03_Celebrate-Childs-Progress.jpg";
import sect3Img from "../../../../assets/img/article/article4/04_Screen-and_Support.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";

export default function Article4() {
  return (
    <div className="container-fluid pmw-article4-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Understanding Your Child's Milestones</h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          {/* <div
            className="hero-img"
            style={{ backgroundImage: `url(${mainImg})` }}
          >
            <span className="sr-only">Hero image</span>
          </div> */}
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="family out on the park playing with balls"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            Watching your child learn how to walk, create relationships, and
            develop their own unique personality is an amazing process! This is
            called child development, and you may have noticed your child's
            behavior becomes more complex as they grow. Children have certain
            behaviors and skills that they develop according to their age. Even
            though your child is unique, most children share these milestones.
            Learning them can give you an idea of what to expect as your child
            grows. These milestones include new ways that they communicate,
            problem solve, and interact with others.
          </p>
          <p className="pmw-mb25">
            Here are some tips to spot and encourage healthy development in your
            child.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Know the child development milestones, and observe and celebrate your
          child's progress.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="father reading book for two daughters at the park"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            When you know the skills that most children can have at certain
            ages, you can track how your child is developing according to their
            current stage of life. You'll understand when your expectations fit
            with their age and if you might be expecting too much from your
            growing child. For instance, knowing when most children potty train
            means you won't be too hard on yourself if you try to do it too
            early. This way, you can provide better care by celebrating when
            things are going well and have the right tools to spot issues early
            on.
          </p>
          <p className="pmw-mb25">
            If you have any concerns about your child reaching their milestones,
            reach out to your pediatrician. They can make sure everything is
            okay, or help you take care of an issue you're concerned about. Your
            child's pediatrician will help your child get the support they need
            as early as possible, so they can thrive.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Practice positive parenting by nurturing your child's growth.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="child practicing walking with mother and father in the living room"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            There are a couple ways you can help your child develop healthily at
            different phases of their life. You can do it with the right mix of
            nurturing, protection, and support. This will help your child be
            healthy, safe, and successful.
          </p>
          <p>
            When your child is very young, it's important to spend time talking,
            reading, playing, and cuddling with them. Praise, encouragement, and
            consistent rules are very important at this age. So is helping them
            with language, problem-solving, and exploring their environment.
          </p>
          <hr />
          <p className="callout">
            Positive parenting makes it easier for your child to succeed in
            school, at work, and all stages of their life.
          </p>
          <hr />
          <p>
            As they grow into a teenager, you can help your child become more
            independent by supporting their good choices and helping them create
            healthy relationships. Ask questions, and listen with care to
            understand their relationships with family, friends, and teachers.
            Provide guidance if they need it. Help them understand the
            foundations of a healthy relationship, like honesty, trust, and
            respect. These skills and expectations of others will allow them to
            build safe and caring relationships in the future.
          </p>
          <p className="pmw-mb25">
            Positive parenting makes it easier for your child to succeed in
            school, at work, and all stages of their life.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Screen and support your child according to their needs.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="father and daughter talking with a doctor"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Just like a dentist or eye doctor makes sure your child's teeth and
            vision are healthy, a screening by your pediatrician can make sure
            your child is on track with their language, social, behavioral, and
            motor skills &#8212; or let you know if there is an issue.
            Sometimes, the age that children reach these milestones can be
            different, so it doesn't always mean something is wrong. Your
            healthcare provider can help you understand if you should address an
            issue with a delay, or if everything is fine.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            As you love and care for your growing child, knowing their
            milestones can help you understand a lot more about how your child
            is learning, growing, and moving along the pathway of their life.
            You'll be able to provide better support, have a stronger
            relationship, and learn the best tools to quickly respond to their
            needs.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>View Other Articles</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adults and children sitting outside laughing and talking"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/making-connections/article/article1">
            <strong>Making and Modeling Healthy Social Connections</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mom and daughter meditating"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/managing-feelings/article/article2">
            <strong>Four Keys to Healthy Emotional Expression</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <img
              src={article3Img}
              alt="parent and supporter hugging each other"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/seeking-and-accepting-help/article/article3">
            <strong>Finding and Securing Help When You Need It</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
