import { useState, useEffect, useRef } from "react";

import "./ProgressBar.scss";

export default function ProgressBar() {
  const [scrollProgress, setScrollProgress] = useState(null);

  const progressBarEl = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", updateProgressBar);
  }, []);

  const updateProgressBar = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    //progressBarEl.current.style.width = `${scrolled}%`;
    setScrollProgress(scrolled);
  };
  return (
    <div className="progress-bar__wrapper">
      <div
        className="progress-bar"
        ref={progressBarEl}
        style={{ width: `${scrollProgress}%` }}
      ></div>
    </div>
  );
}
