import { useEffect, useRef } from "react";

import "./ScrollToTopBtn.scss";

export default function ScrollToTopBtn() {
  const scrollUpEl = useRef(null);
  let showTimeout;

  useEffect(() => {
    initScrollup();
    document.addEventListener("scroll", () => resetTimeout());

    return () => {
      document.removeEventListener("scroll", () => resetTimeout());
    };
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const initScrollup = () => {
    showTimeout = setTimeout(hideScrollup, 3000);
  };

  const showScrollup = () => {
    scrollUpEl.current.classList.remove("hidden");
  };

  const hideScrollup = () => {
    scrollUpEl.current.classList.add("hidden");
  };

  const resetTimeout = () => {
    showScrollup();
    clearTimeout(showTimeout);
    initScrollup();
  };

  return (
    <div className="pmw-scrolltop-btn-wrapper hidden" ref={scrollUpEl}>
      <button className="pmw-scrolltop-btn" onClick={scrollToTop}>
        <i className="fas fa-chevron-up"></i>
      </button>
    </div>
  );
}
