import { LanguageContext } from "../../App.js";
import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./LanguageBar.scss";

export default function LanguageBar() {
  // Find the url location and navigate to a new one. If spanish go english if english go spanish
  const location = useLocation();
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(LanguageContext);
  useEffect(() => {
    setLanguage(location.pathname.startsWith("/es") ? true : false);
  }, []);

  const toggleLanguage = (btn) => {
    if (btn === "en") {
      // I want to change it to english
      if (!language) {
        return null; //if it's already english do nothing
      } else if (location.pathname.startsWith("/es")) {
        const englishUrl = location.pathname.slice(3); // if it is not english then it is spanish so take the 1st 3 url characters which will be "/es"
        setLanguage(false);
        navigate(englishUrl);
        document.getElementsByTagName("META")[3].content =
          "Get parenting tips on what to know as children grow. Learn how to manage stress, build resilience, and nurture positive relationships to become a better parent.";
      }
    }
    if (btn === "es") {
      // I want to change it to spanish
      if (language) {
        return null; //if it's already spanish do nothing
      } else {
        const spanishUrl = `/es${location.pathname}`; //add /es to the url to make it the spanish component
        setLanguage(true);
        navigate(spanishUrl);
        document.getElementsByTagName("META")[3].content =
          "Consiga consejos sobre qué saber mientras los niños crecen. Aprenda manejar el estrés, construir resiliencia y nutrir las relaciones positivas para convertirse en padre mejor.";
      }
    }
  };
  return (
    <>
      <div className="language-bar">
        {language ? (
          <button className="lang-btn" onClick={() => toggleLanguage("en")}>
            English
          </button>
        ) : (
          ""
        )}
        {!language ? (
          <button className="lang-btn" onClick={() => toggleLanguage("es")}>
            Español
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
