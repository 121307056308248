import React from "react";
import { Link } from "react-router-dom";
import PaginationCarousel from "../../components/PaginationCarousel/PaginationCarousel";

// SASS
import "./About.scss";

export default function About() {
  return (
    <div className="container-fluid pmw-about-wrapper">
      <div className="row pmw-about-r1 pmw-limit-width">
        <h2 className="pmw-mt25 pmw-mb25">About</h2>
        <p className="pmw-mb25">
          Find helpful tools and resources to help you be the best parent you
          can be. Choose an item from the topics below to learn more.
        </p>
      </div>

      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col">
          <div className="accordion accordion-flush" id="aboutAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseOne"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseOne"
                >
                  <p className="mb-0">What is PEACH Parenting?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingOne"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      PEACH Parenting (Promoting Education, Awareness,
                      Connection, and Health) is a mobile website brought to you
                      by the Georgia Division of Family and Children Services
                      Prevention and Community Support Section. Use PEACH
                      Parenting to access information and resources designed to
                      help you become a stronger parent. Learn ways to adapt to
                      stress by responding in healthy ways so that you can
                      provide better care to your children. This is called
                      resilience, and you'll learn more about how to build it on
                      this website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseTwo"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseTwo"
                >
                  <p className="mb-0">What is resilience?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingTwo"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      Resilience is the ability to manage your reactions to
                      stress so that you can adapt and cope when you experience
                      tough times. You'll learn more about how to become a more
                      resilient parent on this website, and how it positively
                      affects your family's well-being.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseThree"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseThree"
                >
                  <p className="mb-0">
                    Why is resilience important to parenting?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingThree"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      Resilience is about finding a way to be the parent you
                      want to be, no matter what else is going on around you.
                      When you have resilience, you can manage stress better and
                      help ensure the well-being of your family. Research shows
                      that children that grow up with more resilience do better
                      at school, are physically healthier, and are better at
                      managing their emotions and relationships. Throughout this
                      site, you'll learn how resilience matters in your
                      day-to-day experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseFour"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseFour"
                >
                  <p className="mb-0">
                    What are some ways I can practice becoming more resilient?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingFour"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p>
                      Start your journey of becoming a strong, resilient parent
                      by connecting with tips, resources, and guidance to help
                      you along the way. Like a rubber band that has the ability
                      to stretch without breaking, you can develop the ability
                      to stretch and grow as a parent by exploring and using
                      these resources according to your needs.
                    </p>
                    <p className="mb-0">
                      Just by being here, you're already on your way to becoming
                      a more empowered, strong, and resilient parent. Remember
                      that developing resilience is your own personal journey,
                      and you can use what you know to guide your children on
                      their own journey towards resilience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseFive"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseFive"
                >
                  <p className="mb-0">What will I learn on this website?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingFive"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      Throughout this site, you'll learn how to make stronger
                      connections, manage your feelings, find help when you need
                      it, and discover information about how kids grow and
                      learn. With these evidence-based resources, you'll learn
                      and practice skills to cope with everyday stresses, and
                      bounce back when things aren't going well. When you're
                      more prepared to handle the challenges that go along with
                      being a parent, your whole family can thrive. Let's become
                      resilient together!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseSix"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseSix"
                >
                  <p className="mb-0">How will I learn it?</p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingSix"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p>
                      You'll have a couple different ways to learn about
                      resilience tools on this website, depending on how much
                      time you've got to learn. How and what you learn here is
                      your choice!
                    </p>
                    <p>
                      <strong>ONE.</strong> If you have one minute, read quick,
                      high-level tips for the topic area you've selected.
                    </p>
                    <p>
                      <strong>THREE.</strong> If you only have three minutes,
                      read quick tips and an article on your selected topic.
                    </p>
                    <p className="mb-0">
                      <strong>FIVE.</strong> If you have five minutes, read the
                      quick tips, check out the article, and complete an
                      activity on the topic page.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="aboutAccordionHeadingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#aboutAccordionCollapseSeven"
                  aria-expanded="false"
                  aria-controls="aboutAccordionCollapseSeven"
                >
                  <p className="mb-0">
                    What if I want more help or information than what is
                    available here?
                  </p>
                </button>
              </h2>
              <div
                id="aboutAccordionCollapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="aboutAccordionHeadingSeven"
                data-bs-parent="#aboutAccordion"
              >
                <div className="accordion-body">
                  <div className="accordion-content">
                    <p className="mb-0">
                      You can always go to the{" "}
                      <Link to="/resource-center">Resource Center</Link> and on
                      each of the five topic pages to find additional
                      information and links to helpful resources.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25 text-center">Topic Areas</h2>
        </div>
      </div>

      <div className="row pmw-about-r3 pmw-mb75 pmw-limit-width">
        <div className="col carousel-wrapper1">
          <PaginationCarousel></PaginationCarousel>
        </div>
      </div>
    </div>
  );
}
