import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing2.scss";

import MainHeader from "../../../../components/MainHeader/MainHeaderES";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreviewES";

import heroImg from "../../../../assets/img/main/Managing-Feelings.png";
import headingIcon from "../../../../assets/img/icon/Managing-Feelings-Icon.png";
import articleImg from "../../../../assets/img/article/article2/01_Main-Image.jpg";

export default function Landing2() {
  return (
    <>
      <MainHeader
        heading1={"Cómo Manejar"}
        heading2={"Los Sentimientos"}
        icon={headingIcon}
        iconAlt={"Ícono de manejo de sentimientos"}
        heroImg={heroImg}
        heroImgAlt={"Famila de 3 meditando"}
        heroImgClass={"light-purple-bg"}
        tips={true}
        article={true}
        video={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              Enséñeles a sus hijos a comunicar, comprender y manejar sus
              emociones creando hábitos y rutinas saludables y dedicando tiempo
              a expresar sus sentimientos juntos. Al hacerlo, los ayudará a
              convertirse en mejores amigos, estudiantes y familiares.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-purple-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Consejos Rápidos</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>
              Estas son algunas herramientas que puede usar para ayudar a sus
              hijos a comprender y hablar sobre sus sentimientos.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Cuídese.</strong> incluso cuando parezca que tiene cosas
              más importantes que hacer, siempre que sea posible, es esencial
              que priorice su cuidado personal para que pueda estar
              completamente presente para sus hijos y para usted.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Construya rutinas saludables.</strong> arme rutinas
              diarias que tengan objetivos claros para que sus hijos desarrollen
              más confianza y se sientan seguros.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Intente escuchar a sus hijos de forma activa.</strong>{" "}
              puede ayudar a sus hijos a expresar y a nombrar sus emociones
              escuchando y repitiendo lo que dicen. Reflexionen juntos sobre lo
              que podrían estar sintiendo y experimentando.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">4</div>
            <div className="col-11 txt-box">
              <strong>Desarrolle la creatividad y reflexione.</strong> elija una
              actividad creativa para hacer con sus hijos. Aproveche esta
              oportunidad para expresar sus sentimientos compartidos y construir
              una relación más fuerte.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Artículo</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={
                "Cuatro Claves para Expresar las Emociones de Forma Saludable"
              }
              img={articleImg}
              imgAlt={"Mamá e hija meditando"}
              desc={
                "¿Sabía que ayudar a sus hijos a expresar y regular sus emociones en un entorno seguro y afectuoso es una de las mejores habilidades que puede enseñarles? Es crucial para que crezcan saludables y para que puedan crear relaciones positivas y gratificantes en el futuro. Con estas habilidades, sus hijos podrán manejar..."
              }
              link={"/es/managing-feelings/article/article2"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="video">
          <div className="col-12">
            <h2 className="pmw-mb25">Vídeo</h2>
            <p className="pmw-mb25">
              ¿De qué forma puede ser consciente de sus sentimientos, nombrarlos
              y manejarlos?
            </p>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12 text-center">
            <iframe
              width="315"
              height="560"
              src="https://www.youtube.com/embed/kL0ZLKU3nMg?si=cYhY17TBm7uEni8d&rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item purple">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button purple collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Otros Recursos Sobre el Manejo de los Sentimientos
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content purple">
                      <p>
                        <a
                          href="https://cssp.org/wp-content/uploads/2018/08/HO-2.4-Social-Emotional-Skill-Building-worksheet.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Use estas actividades con su hijo para desarrollar
                          habilidades emocionales&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.cdc.gov/parents/essentials/communication/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Aprenda a construir una comunicación sólida con su
                          hijo&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.mhanational.org/sites/default/files/BACK%20TO%20SCHOOL%202014%20-%20Healthy%20Mental%20and%20Emotional%20Development.pdf"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Sepa qué esperar para el desarrollo emocional de su
                          hijo&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
