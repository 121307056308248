import React from "react";
import { Link } from "react-router-dom";
import PaginationCarousel2 from "../../components/PaginationCarousel2/PaginationCarousel2";
import PaginationCarousel2ES from "../../components/PaginationCarousel2/PaginationCarousel2ES";

// SASS
import "./Home.scss";

import heroImg1 from "../../assets/img/main/DFCS__Hero-Image_Horizontal_Spanish.png";
import heroImg2 from "../../assets/img/main/DFCS__Homepage-Hero-Image_Spanish.png";
import icon1 from "../../assets/img/icon/Making-Connections-Icon.png";
import icon2 from "../../assets/img/icon/Managing-Feelings-Icon.png";
import icon3 from "../../assets/img/icon/Seeking-Help-Icon.png";
import icon4 from "../../assets/img/icon/Understanding-Milestones-Icon.png";

export default function Home() {
  return (
    <div className="container-fluid pmw-home-wrapper">
      <div className="row pmw-home-r1 pmw-mb50">
        <div className="col px-0">
          <img
            src={heroImg1}
            alt="Diverse group of hands holding sign saying Resilient parents to lead to healthier children"
            className="img-fluid d-none d-sm-block"
          />
          <img
            src={heroImg2}
            alt="Diverse group of hands holding sign saying Resilient parents to lead to healthier children"
            className="img-fluid d-block d-sm-none"
          />
        </div>
      </div>
      <div className="row pmw-home-r2 pmw-mb25 pmw-limit-width">
        <div className="col-12">
          <h2 className="orange-btn-txt">
            ¡Le damos la bienvenida a PEACH Parenting!
          </h2>
          <p>
            <strong className="dark-lime-txt">
              PEACH: Promoting Education, Awareness, Connection, and Health
            </strong>
          </p>
          <p className="pmw-mb50">
            Nos alegra que esté aquí para aprender con nuestra&nbsp;
            <br className="d-none d-sm-block" />
            comunidad de padres y cuidadores.
          </p>

          <Link className="pmw-btn orange" to="/es/about">
            Acerca de la iniciativa
          </Link>
        </div>
      </div>
      <div className="pmw-home-r3 pmw-mt50 pmw-mb10 pmw-limit-width">
        <div className="row">
          <div className="col">
            <h2 className="pmw-mb25">Generadores de Resiliencia</h2>
            <p className="pmw-mb15 pmw-w80">
              Aprenda a mejorar sus habilidades para recuperarse del estrés
              &#8212; también llamadas habilidades de resiliencia &#8212; con
              estos cuatro generadores de resiliencia.
            </p>
            <p className="pmw-mb25 pmw-w80 small-txt">
              <i>Haga clic en un tema a continuación para comenzar.</i>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-green-bg"
              to="/es/making-connections"
            >
              <img src={icon1} alt="Making Connections icon" className="icon" />
              <p className="mb-0">
                Cómo Hacer
                <br />
                Conexiones
              </p>
            </Link>
          </div>
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-purple-bg"
              to="/es/managing-feelings"
            >
              <img src={icon2} alt="Managing Feelings icon" className="icon" />
              <p className="mb-0">
                Cómo Manejar
                <br />
                Los Sentimientos
              </p>
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50">
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-teal-bg"
              to="/es/seeking-and-accepting-help"
            >
              <img
                src={icon3}
                alt="Seeking and Accepting Help icon"
                className="icon"
              />
              <p className="mb-0">
                Cómo buscar y
                <br />
                Aceptar Ayuda
              </p>
            </Link>
          </div>
          <div className="col-6 link-box-wrapper">
            <Link
              className="link-box light-pink-bg"
              to="/es/understanding-milestones"
            >
              <img
                src={icon4}
                alt="Understanding Milestones icon"
                className="icon"
              />
              <p className="mb-0">
                Cómo Comprender
                <br />
                Los Hitos
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="pmw-home-r4 pmw-mb75">
        <div className="row pmw-limit-width">
          <div className="col-12">
            <h2 className="pmw-mb25">Cómo Utilizar Este Sitio Web</h2>
          </div>
        </div>
        <div className="row pmw-limit-width">
          <div className="col carousel-wrapper2">
            <PaginationCarousel2ES></PaginationCarousel2ES>
          </div>
        </div>
      </div>

      <div className="row pmw-home-r5 pmw-mb50 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">Recursos</h2>
          <p className="pmw-mb50 pmw-w80">
            Aquí encontrará una lista completa de recursos de todas secciones de
            este sitio web, además de otros recursos que le resultarán útiles.
          </p>

          <Link className="pmw-btn orange" to="/es/resource-center">
            Todos Los Recursos
          </Link>
        </div>
      </div>
    </div>
  );
}
