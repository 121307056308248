import React from "react";

// SASS
import "./MainHeader.scss";

export default function MainHeader({
  heading1,
  heading2,
  icon,
  iconAlt,
  heroImg,
  heroImgAlt,
  heroImgClass,
  tips,
  article,
  infographic,
  video,
  map,
  audio,
  milestones,
}) {
  return (
    <>
      <div className="container-fluid">
        <div className="row pmw-mainheader-r1 sect-p25 pmw-limit-width">
          <div className="col-8 col-sm-9 pmw-mh-col">
            <h2>
              {heading1}
              <br />
              {heading2}
            </h2>
          </div>
          <div className="col-4 col-sm-3 pmw-mh-col">
            <img src={icon} alt={iconAlt} className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="pmw-mainheader-r2">
          <div className="row pmw-limit-width">
            {tips === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#quick-tips");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Quick Tips</p>
              </div>
            )}
            {article === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#article");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Article</p>
              </div>
            )}
            {infographic === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#infographic");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Infographic</p>
              </div>
            )}
            {video === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#video");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Video</p>
              </div>
            )}
            {audio === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#audio");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Audio Clip</p>
              </div>
            )}
            {map === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#local-services");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Local Services</p>
              </div>
            )}
            {milestones === true && (
              <div
                className="col"
                onClick={() => {
                  const anchor = document.querySelector("#milestones");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              >
                <p>Milestones</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row pmw-mainheader-r3 pmw-mb25">
          <div className={`col px-0 ${heroImgClass}`}>
            <img src={heroImg} alt={heroImgAlt} className="main-hero-img" />
          </div>
        </div>
      </div>
    </>
  );
}
