import { useContext } from "react";

import BuildContext from "../../contexts/BuildContext/BuildContext";

import "./Step.scss";

export default function Step({
  stepSection,
  stepNumber,
  height,
  stepDescription,
  offset,
}) {
  const { buildEnv } = useContext(BuildContext);

  return (
    <div
      className={`step ${stepSection} ${stepSection}-${stepNumber}`}
      style={{ height: height }}
      data-step={stepNumber}
      data-offset={offset}
    >
      {buildEnv === "development" &&
        `${stepSection}:${stepNumber} - ${stepDescription}`}
    </div>
  );
}
