import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article2.scss";

import mainImg from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article2/02_Self-Care.jpg";
import sect2Img from "../../../../assets/img/article/article2/03_Healthy-Routines.jpg";
import sect3Img from "../../../../assets/img/article/article2/04_Active-Listening.jpg";
import sect4Img from "../../../../assets/img/article/article2/05_Creative-Activites.jpg";
import article1Img from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article2() {
  return (
    <div className="container-fluid pmw-article2-wrapper">
      <div className="row pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Cuatro claves para expresar las emociones de forma saludable
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="Mamá e hija meditando"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            ¿Sabía que ayudar a sus hijos a expresar y regular sus emociones en
            un entorno seguro y afectuoso es una de las mejores habilidades que
            puede enseñarles? Es crucial para que crezcan saludables y para que
            puedan crear relaciones positivas y gratificantes en el futuro. Con
            estas habilidades, sus hijos podrán manejar mejor el estrés,
            resolver más problemas y sortear desafíos.
          </p>
          <p className="pmw-mb25">
            Estas son algunas ideas para desarrollar lo que ya está haciendo
            para ayudar a sus hijos a controlar sus sentimientos.
          </p>
        </div>
      </div>

      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Cuídese y priorice su cuidado personal.
        </div>
      </div>

      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="mamá sentada encima de una colchoneta de yoga bebiendo jugo saludable"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Para ayudar a sus hijos con sus emociones y relaciones, es
            importante que se ocupe de sus propias necesidades mentales,
            sociales y emocionales. Al igual que una batería que necesita
            recargarse para funcionar bien, usted debe tomarse tiempo para
            recargarse completamente para ser su mejor versión. Si dedica tiempo
            para satisfacer sus propias necesidades, podrá presentarse como un
            mejor padre para sus hijos y familiares. Podrá desarrollar su
            resiliencia y hacer frente a los desafíos de la vida.
          </p>
          <hr />
          <p className="callout">
            Si dedica tiempo para satisfacer sus propias necesidades, podrá
            presentarse como un mejor padre para sus hijos y familiares.
          </p>
          <hr />
          <p>
            Incluso si no tiene mucho tiempo, puede priorizar su cuidado
            personal con solo unos minutos al día. Intente hacer una cosa que le
            guste todos los días para priorizar su salud física o mental.
          </p>
          <ul>
            <li>
              Para empezar, intente escribir un diario, meditar, dar un paseo,
              darse un baño caliente o hablar con amigos.
            </li>
            <li className="pmw-mb25">
              Cuide su salud física comiendo bien, durmiendo lo suficiente y
              haciendo ejercicio.
            </li>
          </ul>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Cree rutinas saludables para fomentar la seguridad y previsibilidad
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="madre cepillando los dientes de las hijas"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Tener rutinas predecibles sirve para fomentar la salud emocional de
            su hijo al crear seguridad y apoyo. Las rutinas también les enseñan
            a los niños hábitos saludables, como cepillarse los dientes. Y
            también son beneficiosas para usted &#8212; le ayudan a mantener el
            orden cuando la vida se complica. Las rutinas le facilitan pasar
            tiempo con sus hijos y fortalecer su relación.
          </p>
          <p className="pmw-mb25">
            Intente construir una rutina diaria que funcione según su horario
            para que pueda cumplirla. Para comenzar, piense en las actividades
            disfrutan hacer juntos. Dedique tiempo para comer juntos o leer una
            historia para dormir a la misma hora todas las noches. Sus rutinas
            deben ser regulares, planificadas y predecibles, pero flexibles en
            caso de que necesite hacer algún cambio.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Escuche a sus hijos atentamente para ayudarlos expresar y nombrar sus
          emociones.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="hija en silla de ruedas y padre besando la nariz"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Ayudar a sus hijos a hablar regularmente sobre cómo se sienten a
            través de sus altibajos les permite comprender sus emociones. Si los
            niños saben que usted los escucha y acepta sus sentimientos, ellos
            también podrán aceptarlos y comprenderlos sin miedo. También podrán
            desarrollar autocompasión y empatía hacia los demás.
          </p>
          <p className="pmw-mb25">
            Escuche a sus hijos atentamente haciéndoles preguntas sobre sus
            emociones y reflexionando sobre lo que dicen, siempre manteniendo
            contacto visual. De esta forma, sus hijos sentirán que los ve, que
            los cuida y que los apoya cuando expresen sus emociones. También
            puede ser un ejemplo de cómo gestionar emociones saludables dejando
            que sus hijos vean las suyas.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">4</div>
        <div className="col-10 col-sm-11 txt-box">
          Realicen actividades creativas juntos y celebren la expresión
          emocional.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect4Img}
            alt="padre e hija escribiendo y dibujando juntos"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Las actividades creativas permiten que sus hijos expresen un lado
            diferente y que aprendan más sobre ellos mismos para comprender sus
            sentimientos. Observe lo que les gusta hacer a sus hijos para
            realizar estas actividades juntos. Puede ser cocinar, pintar y
            dibujar, escribir poesía o bailar, o cualquier otra actividad
            creativa.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p className="pmw-mb25">
            Crear momentos compartidos para hablar y expresar emociones con sus
            hijos requiere una práctica constante &#8212; al igual que los
            deportes o la escuela. A través de pequeños hábitos cotidianos,
            tiene el poder de crear un espacio seguro y emocionalmente favorable
            para sus hijos. Les está dando un kit de herramientas para
            desarrollar resiliencia, sortear desafíos con éxito y prosperar en
            la escuela, el trabajo y la vida.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>Ver Otros Artículos</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/making-connections/article/article1">
            <img
              src={article1Img}
              alt="adultos y niños sentados afuera riendo y hablando"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/making-connections/article/article1">
            <strong>
              Cómo Establecer y Modelar Conexiones Sociales Saludables
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <img
              src={article3Img}
              alt="padre y partidario abrazándose unos a otros"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <strong>Cómo Encontrar y Asegurar Ayuda Cuando la Necesita</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="familia en el parque jugando con pelotas"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <strong>Cómo Comprender los Hitos de sus Hijos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
