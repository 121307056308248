import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Landing4.scss";

import MainHeader from "../../../../components/MainHeader/MainHeader";
import ArticlePreview from "../../../../components/ArticlePreview/ArticlePreview";

import heroImg from "../../../../assets/img/main/Understanding-Milestones.png";
import headingIcon from "../../../../assets/img/icon/Understanding-Milestones-Icon.png";
import articleImg from "../../../../assets/img/article/article4/01_Main-Image.jpg";
import hopImg from "../../../../assets/img/main/Milestones.png";

export default function Landing4() {
  return (
    <>
      <MainHeader
        heading1={"Understanding"}
        heading2={"Milestones"}
        icon={headingIcon}
        iconAlt={"Understanding Milestones Icon"}
        heroImg={heroImg}
        heroImgAlt={"child growing up from toddler to adult"}
        heroImgClass={"light-pink-bg"}
        tips={true}
        article={true}
        milestones={true}
      />
      <div className="container-fluid pmw-main-wrapper">
        <div className="row pmw-limit-width">
          <div className="col">
            <p className="pmw-mb25">
              As a caregiver, it's exciting to see your child do new things as
              they get older. Understanding important signs and signals to look
              out for as your child grows enables you to make sure they are
              supported, and helps you spot issues that might need extra
              attention and care.
            </p>
          </div>
        </div>
        <div
          className="row tips-wrapper sect-p25 light-pink-bg pmw-mb25 pmw-limit-width"
          id="quick-tips"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Quick Tips</h2>
            <span>1 MIN</span>
          </div>
          <div className="col-12">
            <p>
              Learn tools you can use to nurture your child's healthy
              development as they grow.
            </p>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">1</div>
            <div className="col-11 txt-box">
              <strong>Know the milestones.</strong> Learn about{" "}
              <a
                href="https://www.cdc.gov/ncbddd/actearly/index.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Factearly%2Findex.html"
                target={"_blank"}
                rel="noreferrer"
              >
                child development milestones&nbsp;
                <sup>
                  <i className="far fa-external-link"></i>
                </sup>
              </a>
              , and track your child's progress as they get older. Celebrate
              when your child reaches them and take action if you have a
              concern.
            </div>
          </div>
          <div className="row pmw-mb25">
            <div className="col-1 num-box ps-0">2</div>
            <div className="col-11 txt-box">
              <strong>Practice positive parenting.</strong> Healthy child
              development is the result of positive parenting. You can create
              routines and habits that help your child learn and grow, like
              reading together or sharing a meal. Make sure they are healthy and
              safe, and show warmth and sensitivity when caring for them.
            </div>
          </div>
          <div className="row">
            <div className="col-1 num-box ps-0">3</div>
            <div className="col-11 txt-box">
              <strong>Screen and support.</strong> Follow up with your
              pediatrician about any concerns with your child's learning,
              behavior, or growth. A screening can help you and your healthcare
              provider recognize and address any issues.
            </div>
          </div>
        </div>
        <div
          className="row article-wrapper sect-p25 pmw-limit-width"
          id="article"
        >
          <div className="col-12 heading pmw-mb25">
            <h2>Article</h2>
            <span>3 MIN</span>
          </div>
          <div className="col-12">
            <ArticlePreview
              title={"Understanding Your Child's Milestones"}
              img={articleImg}
              imgAlt={"family out on the park playing with balls"}
              desc={
                "Watching your child learn how to walk, create relationships, and develop their own unique personality is an amazing process! This is called child development, and you may have noticed your child's behavior becomes more complex as they grow. Children have certain behaviors and skills that they develop..."
              }
              link={"/understanding-milestones/article/article4"}
            />
          </div>
        </div>
        <div className="row pmw-limit-width" id="milestones">
          <div className="col-12">
            <h2 className="pmw-mb25">Milestones</h2>
            <p className="pmw-mb25">
              Do you know the child development milestones? New skills your
              child develops over time &#8212; like crawling, talking, or
              walking &#8212; are called developmental milestones. Learn more
              about what to expect as your child grows.
            </p>
          </div>
        </div>

        <div className="row pmw-limit-width">
          <div className="text-center hop-box pmw-mb50">
            <img src={hopImg} alt="hopscotch" className="" />
            <Link
              className="pmw-btn pink"
              to="/understanding-milestones/milestones"
            >
              View Milestones
            </Link>
          </div>
        </div>
        <div className="row pmw-mb50 pmw-limit-width">
          <div className="col-12">
            <div className="accordion" id="resourceAccordion">
              <div className="accordion-item pink">
                <h2 className="accordion-header" id="resourceAccordion-heading">
                  <button
                    className="accordion-button pink collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#resourceAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="resourceAccordion-collapse"
                  >
                    <p className="mb-0">
                      Other Resources on Understanding Milestones
                    </p>
                  </button>
                </h2>
                <div
                  id="resourceAccordion-collapse"
                  className="accordion-collapse collapse"
                  aria-labelledby="resourceAccordion-heading"
                >
                  <div className="accordion-body">
                    <div className="accordion-content pink">
                      <p>
                        <a
                          href="https://development.decal.ga.gov/#/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Track your child's milestones according to their
                          age&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Get positive parenting tips from CDC&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://helpmegrowmn.org/HMG/HelpfulRes/Articles/HowEncourageBrainDev/index.html"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Make your child's brain stronger with these
                          activities&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                      <p className="mb-0">
                        <a
                          href="https://www.youtube.com/watch?v=rby1JRUD2KU&list=PLdMCz7sQZqM24xpOJS_Kjvm95PiV9T0wS&index=46"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Know what to expect as your child grows&nbsp;
                          <sup>
                            <i className="far fa-external-link"></i>
                          </sup>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
