import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Menu.scss";

import headerIcon1 from "../../assets/img/icon/About-Icon.png";
import headerIcon2 from "../../assets/img/icon/Making-Connections-Icon.png";
import headerIcon3 from "../../assets/img/icon/Managing-Feelings-Icon.png";
import headerIcon4 from "../../assets/img/icon/Seeking-Help-Icon.png";
import headerIcon5 from "../../assets/img/icon/Understanding-Milestones-Icon.png";
import headerIcon6 from "../../assets/img/icon/Resources-Icon.png";

export default function Menu() {
  return (
    <div className="container-fluid pmw-menu-wrapper">
      <div className="row">
        <div className="col-12">
          <Link to="/about" className="menu-item">
            <img src={headerIcon1} alt="About icon" />
            About
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right orange-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/making-connections" className="menu-item">
            <img src={headerIcon2} alt="Making Connections icon" />
            Making Connections
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right green-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/managing-feelings" className="menu-item">
            <img src={headerIcon3} alt="Managing Feelings icon" />
            Managing Feelings
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right purple-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/seeking-and-accepting-help" className="menu-item">
            <img src={headerIcon4} alt="Seeking and Accepting Help icon" />
            Seeking and Accepting Help
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right dark-teal-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/understanding-milestones" className="menu-item">
            <img src={headerIcon5} alt="Understanding Milestones icon" />
            Understanding Milestones
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right pink-btn-txt"></i>
            </div>
          </Link>
        </div>
        <div className="col-12">
          <Link to="/resource-center" className="menu-item">
            <img src={headerIcon6} alt="Resources icon" />
            Resource Center
            <div className="chevron-wrapper">
              <i className="fal fa-chevron-right dark-teal2-txt"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
