import React from "react";
import { Route, Routes } from "react-router-dom";

// SASS
import "./Main2.scss";

import Landing2 from "./Landing2/Landing2";
import Article2 from "./Article2/Article2";
import NotFound from "../../NotFound/NotFound";

export default function Main2() {
  return (
    <Routes>
      <Route path="" element={<Landing2 />} />
      <Route path="/article/article2" element={<Article2 />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
