import React from "react";
import { Link } from "react-router-dom";

// SASS
import "./Article1.scss";

import mainImg from "../../../../assets/img/article/article1/01_Main-Image.jpg";
import sect1Img from "../../../../assets/img/article/article1/02_Healthy-Connections.jpg";
import sect2Img from "../../../../assets/img/article/article1/03_Connect-with-Local-Groups.jpg";
import sect3Img from "../../../../assets/img/article/article1/04_Model-Supportive-Relationships.jpg";
import article2Img from "../../../../assets/img/article/article2/01_Main-Image.jpg";
import article3Img from "../../../../assets/img/article/article3/01_Main-Image.jpg";
import article4Img from "../../../../assets/img/article/article4/01_Main-Image.jpg";

export default function Article1() {
  return (
    <div className="container-fluid pmw-article1-wrapper">
      <div className="row pmw-mt25 pmw-pt25 pmw-limit-width">
        <div className="col">
          <h2 className="pmw-mb25">
            Cómo Establecer y Modelar Conexiones Sociales Saludables
          </h2>
        </div>
      </div>
      <div className="row pmw-article-hero-row">
        <div className="col px-0">
          <div className="pmw-limit-width">
            <img
              src={mainImg}
              alt="adultos y niños sentados afuera riendo y hablando"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row pmw-mt25 pmw-limit-width">
        <div className="col">
          <p>
            Si usted busca fuentes de apoyo emocional y desarrolla prácticas de
            cuidado personal, sus hijos podrán crecer sanos y felices. Conseguir
            el apoyo emocional que necesita puede convertirle en un padre más
            resiliente. Sus hijos necesitan apoyo. De la misma manera, confiar
            en las personas de su red para obtener diferentes tipos de apoyo
            puede ser una valiosa fuente de comodidad y orientación para usted.
            Es posible que necesite un oído empático, una voz de asesoramiento
            útil o un hombro reconfortante en el que apoyarse. Si bien todos
            tienen necesidades diferentes, recuerde que no está solo en este
            viaje. Encontrar ayuda y apoyo es posible, y no necesita mucho
            tiempo.
          </p>
          <hr />
          <p className="callout">
            Recuerde que, si empieza a cuidarse mejor y se toma el tiempo para
            obtener el apoyo que necesita, sus hijos también se beneficiarán.
          </p>
          <hr />
          <p className="pmw-mb25">
            Su trabajo como cuidador se sentirá un poco más fácil cuando se
            ponga en contacto con su familia, amigos y comunidad para obtener
            apoyo. Recuerde que, si empieza a cuidarse mejor y se toma el tiempo
            para obtener el apoyo que necesita, sus hijos también se
            beneficiarán. Estas son algunas ideas que le ayudarán con este
            proceso.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">1</div>
        <div className="col-10 col-sm-11 txt-box">
          Priorice el construir conexiones sociales saludables con otros padres
          y amigos, y dedique tiempo a hacer justamente eso.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect1Img}
            alt="grupo de familias jugando en un parque infantil"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Crear relaciones personales cercanas y de apoyo ayuda con su
            bienestar físico y mental, reduce el estrés y aumenta su
            resiliencia.
          </p>
          <p>
            No es necesario que tenga mucha gente en su vida o que dedique mucho
            tiempo a mantener sus vínculos con los demás. Puede que solo haya un
            par de personas en su círculo &#8212; lo importante es que le ayuden
            a mantener una actitud positiva y a crecer más fuerte, más
            saludable, más consciente y más cerca de sus valores. Estas son
            algunas formas en las que puede comenzar:
          </p>
          <ul>
            <li>
              Dedique pequeñas franjas de su tiempo para participar en una
              reunión virtual con amigos.
            </li>
            <li className="pmw-mb25">
              Llame a sus vecinos para conectarse con ellos. Organice un evento
              familiar en el vecindario u ofrezca ayuda a un vecino que la
              necesite.
            </li>
          </ul>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">2</div>
        <div className="col-10 col-sm-11 txt-box">
          Conéctese con grupos y organizaciones locales de su comunidad.
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect2Img}
            alt="grupo de padres e hijos jugando en una sala de juegos"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Es probable que su familia ya forme parte de algunas comunidades,
            como un vecindario, una comunidad escolar o una comunidad religiosa
            o espiritual. Puede seguir construyendo conexiones sociales
            positivas y apoyo buscando puntos en común con las personas de sus
            comunidades compartidas.
          </p>
          <p>
            Por ejemplo, puede conectarse con otras comunidades de padres que
            entienden lo que es ser cuidador. Busque eventos comunitarios para
            familias, participe en un evento de organizado por una asociación de
            padres y maestros local, o visite las ferias de recursos escolares
            de sus hijos. Quédese un rato más después de los ensayos o las
            prácticas de sus hijos para conectarse con otros padres.
          </p>
          <p>
            También puede unirse a grupos y organizaciones comunitarias que
            compartan sus intereses y valores, o que organicen actividades que
            le gustaría realizar con sus hijos. Por ejemplo, la biblioteca
            local, un centro de recursos familiares o una asociación de
            vecindarios.
          </p>
          <p className="pmw-mb25">
            Conectarse con una comunidad puede ser más fácil de lo que piensa y
            vale la pena el esfuerzo.
          </p>
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col-2 col-sm-1 num-box">3</div>
        <div className="col-10 col-sm-11 txt-box">
          Sea un ejemplo de cómo mantener relaciones positivas y de apoyo .
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col px-0">
          <img
            src={sect3Img}
            alt="familia acurrucada en el sofá riendo"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="row pmw-limit-width">
        <div className="col">
          <p>
            Las relaciones positivas y de apoyo le ayudan a ser el mejor
            cuidador que puede ser. Aligeran la carga de su viaje como cuidador.
            Y esto también beneficia a sus hijos de muchas maneras.
          </p>
          <p>
            Si usted cuenta con una comunidad de apoyo, sus hijos podrán pasar
            tiempo con otros adultos de confianza que los cuidarán. Por ejemplo,
            miembros de su familia extendida, amigos, mentores u otros
            integrantes de la comunidad de su familia. Si usted recibe más apoyo
            y cuidado de otras personas, sus hijos aprenderán a hacer conexiones
            más positivas y de apoyo con los demás.
          </p>
          <p>
            Sus círculos de apoyo serán más grandes. Probablemente se involucren
            en las actividades positivas de las que usted participa y se
            beneficien de ellas. Usted también será un cuidador más feliz y
            saludable para sus hijos.
          </p>
          <p className="pmw-mb25">
            Cuidar a sus hijos significa brindarles distintos tipos de apoyo
            todos los días. Es importante que tanto usted como sus hijos tengan
            conexiones positivas y de apoyo con otras personas. Al igual que una
            planta necesita la cantidad adecuada de agua, luz solar y
            fertilizantes para crecer, usted también necesita el equilibrio
            adecuado de apoyo y cuidado de su comunidad para fortalecerse.
            Conectarse con familiares, amigos y comunidades de apoyo de
            confianza puede ser lo necesario para garantizar que usted y su
            familia tengan vidas más felices y saludables.
          </p>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col">
          <h2>Ver Otros Artículos</h2>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/managing-feelings/article/article2">
            <img
              src={article2Img}
              alt="Mom and daughter meditating"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/managing-feelings/article/article2">
            <strong>
              Cuatro Claves para Expresar las Emociones de Forma Saludable
            </strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb25 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <img
              src={article3Img}
              alt="padre y partidario abrazándose unos a otros"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link
            className=""
            to="/es/seeking-and-accepting-help/article/article3"
          >
            <strong>Cómo Encontrar y Asegurar Ayuda Cuando la Necesita</strong>
          </Link>
        </div>
      </div>
      <div className="row pmw-mb50 pmw-limit-width">
        <div className="col-4 col-sm-3">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <img
              src={article4Img}
              alt="familia en el parque jugando con pelotas"
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-8 col-sm-9 link-box">
          <Link className="" to="/es/understanding-milestones/article/article4">
            <strong>Cómo Comprender los Hitos de sus Hijos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
}
