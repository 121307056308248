import React, { useState, useRef, useEffect } from "react";
import { useAudio } from "../../hooks/AudioHook/AudioHook";

// SASS
import "./AudioPlayer.scss";

import femaleAudio from "../../assets/audio/female.mp3";
import maleAudio from "../../assets/audio/male.mp3";

const imagePath = {
  female: require("../../assets/img/main/Audio-Clip_Female.png"),
  male: require("../../assets/img/main/Audio-Clip_Male.png"),
};

export default function AudioPlayer() {
  const {
    isPlaying,
    duration,
    setDuration,
    currentTime,
    isMute,
    audioPlayer,
    progressBar,
    animationRef,
    volumeBar,
    calculateTime,
    togglePlayPause,
    changeRange,
    changeVolume,
    toggleVolume,
    audioEnded,
    onLoadedMetadata,
    audioVoice,
    setAudioVoice,
    onTrackChange,
    toggleVolumeCtrl,
    volumeActive,
  } = useAudio();

  let userAgent = navigator.userAgent;
  let browserName;
  let removeVolumeBtn = false;

  if (userAgent.match(/Android/i)) {
    browserName = "Android";
    removeVolumeBtn = true;
  } else if (userAgent.match(/iPhone/i)) {
    browserName = "iPhone";
    removeVolumeBtn = true;
  } else if (userAgent.match(/webOS/i)) {
    browserName = "iPhone";
    removeVolumeBtn = true;
  } else if (userAgent.match(/iPad/i)) {
    browserName = "iPad";
    removeVolumeBtn = true;
  } else if (userAgent.match(/BlackBerry/i)) {
    browserName = "BlackBerry";
    removeVolumeBtn = true;
  } else if (userAgent.match(/IEMobile/i)) {
    browserName = "IEMobile";
    removeVolumeBtn = true;
  } else if (userAgent.match(/Opera Mini/i)) {
    browserName = "Opera Mini";
    removeVolumeBtn = true;
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
    removeVolumeBtn = false;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
    removeVolumeBtn = false;
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
    removeVolumeBtn = false;
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
    removeVolumeBtn = false;
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
    removeVolumeBtn = false;
  } else {
    browserName = "Unknown";
  }

  return (
    <>
      <div className="col-12 text-center d-flex audio-toggle-wrapper">
        {/* <button onClick={() => setAudioVoice(true)}>Female</button>
        <button onClick={() => setAudioVoice(false)}>Male</button> */}
        <button
          onClick={() => onTrackChange(true)}
          className={audioVoice ? "pmw-btn white active" : "pmw-btn white"}
        >
          Female
        </button>
        <button
          onClick={() => onTrackChange(false)}
          className={audioVoice ? "pmw-btn white" : "pmw-btn white active"}
        >
          Male
        </button>
      </div>
      <div className="col-lg-5 col-md-6 col-sm-8 col-9">
        <img
          src={audioVoice ? imagePath.female : imagePath.male}
          alt={audioVoice ? "female headshot" : "male headshot"}
          className="img-fluid"
        />
      </div>
      <div className="col-12 text-center justify-content-center d-flex">
        <div className="audio-player">
          <audio
            ref={audioPlayer}
            onEnded={audioEnded}
            onLoadedMetadata={onLoadedMetadata}
          >
            {audioVoice ? (
              <source src={femaleAudio} />
            ) : (
              <source src={maleAudio} />
            )}
          </audio>
          <div className="play-pause-btn-wrapper">
            <button className="play-pause-btn" onClick={togglePlayPause}>
              {isPlaying ? (
                <i className="fas fa-pause"></i>
              ) : (
                <i className="fas fa-play"></i>
              )}
            </button>
          </div>
          <div className="progress-bar-wrapper">
            <span className="time time-1">{calculateTime(currentTime)}</span>
            <input
              type="range"
              id="progress-bar"
              defaultValue={0}
              ref={progressBar}
              onChange={changeRange}
            />
            <span className="time time-2">
              {duration && !isNaN(duration) && calculateTime(duration)}
            </span>
          </div>
          <div
            className="volume-btn-wrapper"
            style={{ display: removeVolumeBtn ? "none" : "flex" }}
          >
            <button className="volume-btn" onClick={toggleVolumeCtrl}>
              {isMute ? (
                <i
                  className="fas fa-volume-mute"
                  style={{ color: volumeActive ? "#707070" : "#57b6b2" }}
                ></i>
              ) : (
                <i
                  className="fas fa-volume"
                  style={{ color: volumeActive ? "#707070" : "#57b6b2" }}
                ></i>
              )}
            </button>
          </div>
          <div
            className="volume-bar-wrapper"
            style={{ display: volumeActive ? "flex" : "none" }}
          >
            <input
              type="range"
              id="volume-bar"
              defaultValue={50}
              ref={volumeBar}
              onChange={changeVolume}
            />
          </div>
        </div>
      </div>
    </>
  );
}
